import React,{useEffect} from "react";
import Navbar 		from './global/navbar';
import FooterNav 	from './global/FooterNav';

import pageBan8 from "../assets/img/bann08.JPG";

const StaffAugmentation = () => {

	let svgTag = '<defs><path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" /></defs><g class="col-md-12 col-xs-12 parallax"><use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" /><use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" /><use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" /><use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" /></g>';

	useEffect(() => {
	    document.title = 'Staff Augmentation';
	  }, []);

	return(

		<div className = "col-md-12 col-xs-12 dcol">
			<Navbar />
			<div className = "col-md-12 col-xs-12 dcol" style = {{marginTop : '60px' }} >
				<div className="col-md-12 col-xs-12 drow mhbanner_wrap deliveryInvoBann" style={{ backgroundImage: `url(${pageBan8})`, minHeight: '230px', position : 'relative' }} >
					<div className="container">
						<div className="col-md-8 col-xs-12 mhbanner_ctn mxsstaffbann_ctn">
							<h3 style={{fontSize: "30px"}}><b style={{color: "#fff"}}>Staff Augmentation </b></h3>
							
						</div>
					</div>
					<div className="wavesWrap wavesMini">
					    <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto"   dangerouslySetInnerHTML={{__html: svgTag }} >
						</svg>
					</div>
				</div>
				<div className="col-md-12 col-xs-12 pagexsHeader">
					<h3>Staff Augmentation</h3>
				</div>

				<div className="col-md-12 col-xs-12 drow ctnbox_wrap" style={{backgroundColor: "#fff"}}>
					<div className="container">
						<div className="col-md-10 col-md-offset-1 col-xs-12 ctnwrapper">
							<div className="col-md-12 col-xs-12 ctnbodyWrap text-center" data-aos="zoom-in">
								<p className="ctnpara">
									The corporate world is sated with challenges – particularly when it comes to the skills and experience of the professionals working for an organization. To ensure that a company’s customers receive a steady state service, the employees need to be trained extensively and be updated with the latest in business environment.
									However, companies face a daunting task finding highly skilled professionals who will deliver quality performance and help the company reach its business objectives. Companies seek to reduce the training costs, thus they need talent, which can give them the confidence of achieving desired results without having to spend huge bucks on their training, and this is where SWANTECH steps in.
				 
								</p>
								
							</div>
						</div>
					</div>
				</div>

				<div className="col-md-12 col-xs-12 drow ctnbox_wrap" >
					<div className="container">
						<div className="col-md-10 col-md-offset-1 col-xs-12 ctnwrapper">
							<div className="col-md-12 col-xs-12 ctnbodyWrap text-center">
								<p className="ctnpara" data-aos="zoom-in">
									We are not your average employment agency, that bulk-hires people. The Staff augmentation services of SWANTECH equip you with a workforce that accelerates the quality and speed of work by developing and managing resources in the most productive manner. They enable you to achieve goals like faster speed-to-market, leverage industry and technical expertise and enhance the reputation of your enterprise in the eyes of your clients and shareholders. The challenges like skill gaps and aggressive project deadlines are adequately handled.
				 
								</p>
								
							</div>
						</div>
					</div>
				</div>

				<div className="col-md-12 col-xs-12 drow ctnbox_wrap" style={{backgroundColor: "#fff"}}>
					<div className="container">
						<div className="col-md-12 col-xs-12 ctnwrapper">
							<div className="col-md-5 col-xs-12 ctnbodyWrap text-center " data-aos="fade-right"  >
								<p className="ctnpara ">
									We make sure that there is a real-time response to the requirements of our clients in respect to their location, technology and the industry in which they are operating.
								</p>
								<p className="ctnpara ">
									SWANTECH Offers Staff Augmentation Service to various sectors:
								</p>
							</div>
							<div className="col-md-7  col-xs-12 ctnListWrap ctnListHalf" style={{padding: "5px 0px 0px 0px"}}>
								<ul>
									<li className="" data-aos="zoom-in">
										
										<span className="sabgColor" > Healthcare Sector</span>
									</li>
									<li className="" data-aos="zoom-in">
										
										<span className="sabgColor" > Energy Sector</span>
									</li>
									<li className="" data-aos="zoom-in">
										
										<span className="sabgColor" > Banking and Finance Sector</span>
									</li>
									<li className="" data-aos="zoom-in">
										
										<span className="sabgColor" > Telecommunications</span>
									</li>
									<li className="" data-aos="zoom-in">
										
										<span className="sabgColor" > Consumer Goods</span>
									</li>
									<li className="" data-aos="zoom-in">
										
										<span className="sabgColor" > Government</span>
									</li>
									<li className="" data-aos="zoom-in" style={{width: "100%" }}>
										
										<span className="sabgColor" > Automation and Construction Industry</span>
									</li>
									
								</ul>
							</div>

							<div className="col-md-12 col-xs-12 ctnbodyWrap text-center">
								<p className="ctnpara " data-aos="zoom-in" >
									With one of our functions being a top-tier employment agency, SWANTECH has to reach and resource in order to connect you to the professionals for all deliverables in the business – be it market analysis, software or hardware analysis, quality assurance or infrastructure management services. We provide you not individuals, but teams.
								</p>
							</div>

						</div>
					</div>
				</div>

			</div>
			<FooterNav />
		</div>

	)

}
export default StaffAugmentation;