import React,{useEffect} from "react";
import Navbar 		from './global/navbar';
import FooterNav 	from './global/FooterNav';

import cantBann from "../assets/img/bann19.jpg";

const ContactUs = () => {

	let svgTag = '<defs><path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" /></defs><g class="col-md-12 col-xs-12 parallax"><use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" /><use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" /><use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" /><use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" /></g>';

	useEffect(() => {
	    document.title = 'Contact Us';
	  }, []);

	return(


		<div className = "col-md-12 col-xs-12 dcol">
			<Navbar />
			<div className = "col-md-12 col-xs-12 dcol" style = {{marginTop : '60px' }} >

				<div className="col-md-12 col-xs-12 drow mhbanner_wrap deliveryInvoBann" style={{ backgroundImage: `url(${cantBann})`, minHeight: '230px', position : 'relative' }} >
					<div className="container">
						<div className="col-md-6 col-xs-12 mhbanner_ctn mxsbanner_ctn">
							<h3 style={{fontSize: "30px"}}>Contact Us</h3>
							
						</div>
					</div>
				</div>
				<div className="col-md-12 col-xs-12 pagexsHeader">
					<h3>Contact Us</h3>
				</div>

				<div className="col-md-12 col-xs-12 drow contactBoxWrap" style={{padding: "50px 0px" }}>
					<div className="container">
					  	<div className="col-md-10 col-md-offset-1 col-xs-12 drow">
							<div className="col-md-12-col-xs-12 contactWrap">
								<div className="col-md-12 col-xs-12 contactStru">
									<div className="col-md-12 col-xs-12 ctnheaderWrap">
										<h3 className="ctnheader">
											Contact Information 
										</h3>
									</div>
									<div className="col-md-12 col-xs-12 drow">
										<div className="col-md-7 col-xs-12 ctninfo_wrap" style={{backgroundColor: "rgb(245, 245, 245)", padding: "10px", marginBottom: "20px" }}>
											<div className="col-md-12 col-xs-12 ctn_info">
												<p className="cpara" style={{fontSize: "16px"}}> 
													(USA) 216 S Pine Ave, <br /> Inverness, FLORIDA 34452
												</p>
											</div>
											<div className="col-md-12 col-xs-12  ctn_info">
												<p className="cpara" style={{fontSize: "16px", textTransform: "lowercase"}} > www.swantechsolution.com</p>
												<p className="cpara" style={{fontSize: "16px", textTransform: "lowercase"}} >  info@swantechsolution.com</p>
											</div>
											<div className="col-md-12 col-xs-12  ctn_info">
												<p className="cpara" style={{fontSize: "16px" }}> +1 (334) 237-4216 </p>
											</div>
										</div>
									</div>
									<div className="col-md-12 col-xs-12 drow">
										<div className="col-md-7 col-xs-12 ctninfo_wrap" style={{backgroundColor: "rgb(245, 245, 245)", padding: "10px", marginBottom: "20px" }}>
											<div className="col-md-12 col-xs-12 ctn_info">
												<p className="cpara" style={{fontSize: "16px" }}> 
													(INDIA) Suite # 201 & 202, Om Sai Arcade, <br/> Lower Tank Bund, Hyderabad (500029)
												</p>
											</div>
											<div className="col-md-12 col-xs-12  ctn_info">
												<p className="cpara" style={{fontSize: "16px", textTransform: "lowercase" }}> info@swantechsolution.com</p>
											</div>
											<div className="col-md-12 col-xs-12  ctn_info">
												<p className="cpara" style={{fontSize: "16px" }}> +91 7837931234 </p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
			<FooterNav />
		</div>
	)
}
export default ContactUs;