import React,{useEffect} from "react";
import Navbar 		from './global/navbar';
import FooterNav 	from './global/FooterNav';

import entmobilityBann from "../assets/img/bann18.jpg";

const EnterpriseMobility = () => {

	let svgTag = '<defs><path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" /></defs><g class="col-md-12 col-xs-12 parallax"><use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" /><use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" /><use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" /><use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" /></g>';

	useEffect(() => {
	    document.title = 'Enterprise Mobility';
	  }, []);

	return(


		<div className = "col-md-12 col-xs-12 dcol">
			<Navbar />
			<div className = "col-md-12 col-xs-12 dcol" style = {{marginTop : '60px' }} >
				<div className="col-md-12 col-xs-12 drow mhbanner_wrap entmobBannWrap" style={{ backgroundImage: `url(${entmobilityBann})`, minHeight: '230px', position : 'relative' }} >
					<div className="container">
						<div className="col-md-9 col-md-offset-2 col-xs-12 mhbanner_ctn mxsbanner_ctn">
							<h3 style={{fontSize: "30px"}}><b style={{color: "#fff"}}>Enterprise Mobility </b></h3>
							
						</div>
					</div>
					<div className="wavesWrap wavesMini">
					    <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto"   dangerouslySetInnerHTML={{__html: svgTag }} >
						</svg>
					</div>
				</div>
				<div className="col-md-12 col-xs-12 pagexsHeader">
					<h3>Enterprise Mobility</h3>
				</div>

				<div className="col-md-12 col-xs-12 drow ctnbox_wrap" style={{backgroundColor: "#fff"}} >
					<div className="container">
						<div className="col-md-12  col-xs-12 ctnwrapper">
							<div className="col-md-12 col-xs-12 ctnheaderWrap">
								<h3 className="ctnheader text-center " data-aos="zoom-in" >
									iOS 
								</h3>
							</div>
							<div className="col-md-12 col-xs-12 ctnListWrap ctnListThree" style={{padding: "30px 0px 0px 0px"}}>
								<ul>
									<li  data-aos="zoom-in">
										
										<span className="enmbgColor" > Map Kit, AV foundation</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="enmbgColor" > Core Graphics</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="enmbgColor" > Apple Push Notification (APN)</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="enmbgColor" > Facebook/Twitter integration</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="enmbgColor" > COCOA Touch</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="enmbgColor" > Core Graphics, Core DATA</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="enmbgColor" > Face book/Twitter integration</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="enmbgColor" > Google SDK</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-12 col-xs-12 drow ctnbox_wrap" >
					<div className="container">
						<div className="col-md-12  col-xs-12 ctnwrapper">
							<div className="col-md-12 col-xs-12 ctnheaderWrap">
								<h3 className="ctnheader text-center " data-aos="zoom-in" >
									Android  
								</h3>
							</div>
							<div className="col-md-12 col-xs-12 ctnListWrap ctnListThree" style={{padding: "30px 0px 0px 0px"}}>
								<ul>
									<li  data-aos="zoom-in">
										
										<span className="enmbgColor"> Location based Service API</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="enmbgColor"> Facebook APIs</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="enmbgColor"> Android media APIs</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="enmbgColor"> Wi-FI APIs</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="enmbgColor"> Asset Packaging tool</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="enmbgColor"> SQLite, MySQL 5.6 database</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="enmbgColor"> Activity Creator</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="enmbgColor"> Android interface description language</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-12 col-xs-12 drow ctnbox_wrap" style={{backgroundColor: "#fff"}} >
					<div className="container">
						<div className="col-md-10 col-md-offset-1  col-xs-12 ctnwrapper">
							<div className="col-md-12 col-xs-12 ctnheaderWrap">
								<h3 className="ctnheader text-center " data-aos="zoom-in" >
									Advantages  
								</h3>
							</div>
							<div className="col-md-12 col-xs-12 ctnListWrap" style={{padding: "30px 0px 0px 0px"}}>
								<ul style={{textAlign: "center" }}>
									<li  data-aos="zoom-in">
										
										<span className="enmbgColor"> Our In-House developed IDE/Framework for building rich client and server side mobility environments.</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="enmbgColor"> Proven expertise in delivering unbiased technology competence to businesses across industry verticals.</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="enmbgColor"> Robust partner network for mutual competence exchange in complex application engineering.</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="enmbgColor"> Dedicated user experience teams to deliver rich customer interaction experiences.</span>
									</li>
									
								</ul>
							</div>
						</div>
					</div>
				</div>

			</div>
			<FooterNav />
		</div>
	)
}
export default EnterpriseMobility;