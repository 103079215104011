import React,{useEffect} from "react";
import Navbar 		from './global/navbar';
import FooterNav 	from './global/FooterNav';

import analBanner from "../assets/img/bann16.jpg";

const Analytics = () => {

	let svgTag = '<defs><path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" /></defs><g class="col-md-12 col-xs-12 parallax"><use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" /><use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" /><use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" /><use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" /></g>';

	useEffect(() => {
	    document.title = 'Analytics';
	  }, []);

	return(
		<div className = "col-md-12 col-xs-12 dcol">
			<Navbar />
			<div className = "col-md-12 col-xs-12 dcol" style = {{marginTop : '60px' }} >

				<div className="col-md-12 col-xs-12 drow mhbanner_wrap deliveryInvoBann" style={{ backgroundImage: `url(${analBanner})`, minHeight: '230px', position : 'relative' }} >
					
					<div className="wavesWrap wavesMini">
					    <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto"   dangerouslySetInnerHTML={{__html: svgTag }} >
						</svg>
					</div>
				</div>
				<div className="col-md-12 col-xs-12 pagexsHeader">
					<h3>Analytics</h3>
				</div>

				<div className="col-md-12 col-xs-12 drow ctnbox_wrap">
					<div className="container">
						<div className="col-md-10 col-md-offset-1 col-xs-12 ctnwrapper">
							<div className="col-md-12 col-xs-12 ctnheaderWrap">
								<h3 className="ctnheader text-center">
									Competencies
								</h3>
							</div>
							<div className="col-md-12 col-xs-12 ctnListWrap ctnListHalf" style={{ padding: "0px 0px 0px 0px" }}>
								<ul>
									
									
									
									<li className="" data-aos="zoom-in">
										
										<span className="databgColor" > Discover relationships and trends between these data silos to leverage them effectively.</span>
									</li>
									<li className="" data-aos="zoom-in">
										
										<span className="databgColor" > Correlate this hitherto unconnected and unstructured data with traditional business KPIs.</span>
									</li>
									<li className="" data-aos="zoom-in">
										
										<span className="databgColor" > Analyze these data sets jointly in a single window by providing dynamic self-service and business user-friendly dashboards.</span>
									</li>
									<li className="" data-aos="zoom-in">
										
										<span className="databgColor" > Strategy and Consulting/ Information Management for Analytics/ Information Convergence / Data Visualization and Predictive Analytics.</span>
									</li>
									<li className="" data-aos="zoom-in">
										
										<span className="databgColor" > Collate the unstructured data hidden in social media.</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>


				<div className="col-md-12 col-xs-12 drow ctnbox_wrap" style={{backgroundColor: "#fff" }}>
					<div className="container">
						<div className="col-md-10 col-md-offset-1 col-xs-12 ctnwrapper">
							<div className="col-md-12 col-xs-12 ctnheaderWrap">
								<h3 className="ctnheader text-center " data-aos="zoom-in">
									Advantages
								</h3>
							</div>
							<div className="col-md-12 col-xs-12 ctnListWrap ctnListHalf" style= {{ padding: "0px 0px 0px 0px" }}>
								<ul>
									
									<li className="" data-aos="zoom-in">
										
										<span className="databgColor" > Experience in all market leading platforms has been helping customers across the globe in identifying their business indicators and build web and mobile.</span>
									</li>
									<li className="" data-aos="zoom-in">
										
										<span className="databgColor" > Experienced in discovering data like never before/ Unleash insights that can reshape business value/ Bridge diversity in information systems/ Fortify market presence.</span>
									</li>
									<li className="" data-aos="zoom-in">
										
										<span className="databgColor" > Partnered with key product leaders to provide best in className next-gen services to our clients.</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>

			</div>
			<FooterNav />
		</div>
	)
}
export default Analytics;