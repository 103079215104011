import React,{useEffect} 	from "react";
import Navbar 				from './global/navbar';
import FooterNav 			from './global/FooterNav';
import carbannImg 			from "../assets/img/bann21.jpg";
import comingSoonImg 		from "../assets/img//coming-soon-2.png"
import PdtImg 		from "../assets/img/feban07.png";
import Pdt02Img 		from "../assets/img/feban08.png";
import Pdt03Img 		from "../assets/img/feban09.png";

const ProductsPage = () => {

	let svgTag = '<defs><path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" /></defs><g class="col-md-12 col-xs-12 parallax"><use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" /><use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" /><use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" /><use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" /></g>';

	useEffect(() => {
	    document.title = 'Products';
	  }, []);

	return(


		<div className = "col-md-12 col-xs-12 dcol">
			<Navbar />
			<div className = "col-md-12 col-xs-12 dcol" style = {{marginTop : '60px' }} >
				<div className="col-md-12 col-xs-12 drow mhbanner_wrap pdtpageBann" style={{ backgroundImage: `url(${carbannImg})`, minHeight: '230px', position : 'relative' }} >
					<div className="container">
						<div className="col-md-10 col-xs-12 mhbanner_ctn mxsbanner_ctn">
							<h3 style={{fontSize: "30px"}}><b style={{color: "#fff"}}>Products </b></h3>
							
						</div>
					</div>
					<div className="wavesWrap wavesMini">
					    <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto"   dangerouslySetInnerHTML={{__html: svgTag }} >
						</svg>
					</div>
				</div>
				<div className="col-md-12 col-xs-12 pagexsHeader">
					<h3>Products</h3>
				</div>

				

				<div className="col-md-12 col-xs-12 drow ctnbox_wrap" style={{backgroundColor: "#fff" }}>
					<div className="container">
						<div className="col-md-10 col-md-offset-1 col-xs-12 ctnwrapper">
							<div className="col-md-12 col-xs-12 ctnbodyWrap text-center">
								<p className="ctnpara " data-aos="zoom-in">
									ReALTr- Global Real Estate ERP system with Customer registration to our website , Customer details relevant to their Country , Property fleet display, Property search , Desired property details , Property continent/Country Government rule and regulation to Buy and or Sell. Customer and Seller property and self-details , Continents related property details with owner name and details, Link for refer every continent Legal T&C’s for seamless purchase and or Sell policies. Buyer and Seller portfolio to purchase and or Sell, Sales team contact details Simplest ways to get connected for any property purchase/sell and post functionality. High end secured payment gateway, Forex exchange on real time for quick action,AMultiple International Banks List.
								</p>

							</div>

							
							
						</div>

						<div className="col-md-12 col-xs-12 ctnwrapper">
								<div className="col-md-7 col-xs-12 dcol " data-aos="fade-right" >
								
									<div className="col-md-12 col-xs-12 ctnbodyWrap text-center">
										<p className="ctnpara " >ReLATr software is a comprehensive , nextgeneration, ready-to-use Organizational ERP system for small,mid-size to large tier organizations. ReLATr Softwareis buil ton latest technologies and standards, and based on industry "BestPractices" infrastructure with established and solid Organization businesslogic. It is user friendly and incredibly easy to use and ass impleas you want, or assophisticatedas your businessneed.</p>
									</div>
								</div>
								<div className="col-md-5 col-xs-12 ctnimgWrap " style={{paddingTop: "40px" }} data-aos="fade-left" >
									<img src={PdtImg} alt="Gap Analysis" className="ctnimg" style = {{width: "100%" }} />
								</div>
							</div>
					</div>
				</div>

				<div className="col-md-12 col-xs-12 drow ctnbox_wrap" >
					<div className="container">
						<div className="col-md-12 col-xs-12 ctnwrapper">

							<div className="col-md-5 col-xs-12 ctnimgWrap " style={{paddingTop: "40px" }} data-aos="fade-left" >
								<img src={Pdt02Img} alt="Gap Analysis" className="ctnimg" style = {{width: "80%" }} />
							</div>

							<div className="col-md-7 col-xs-12 dcol " data-aos="fade-right" >
								
								<div className="col-md-12 col-xs-12 ctnbodyWrap text-center">
									<p className="ctnpara " >Technical Environment :Python , HandoopBIG, Java, Linux, OIC Middleware Cloud , ETL & Datawarehouse, GCP Data , Azure Infrastructure , DEVOPS, Network Infrastructure Automation , Software Quality Test, SERVICE-NOW , Angular JS, Embedded Systems, Business Systems Analysis, Project Management , Program Management.</p>
								</div>
							</div>

							
						</div>
					</div>
				</div>


				<div className="col-md-12 col-xs-12 drow ctnbox_wrap" >
					<div className="container">
						<div className="col-md-12 col-xs-12 ctnwrapper">

							
							
							<div className="col-md-7 col-xs-12 dcol " data-aos="fade-right" >
								
								<div className="col-md-12 col-xs-12 ctnbodyWrap text-center">
									<p className="ctnpara " >Software Cancater The Need Of All Kind Of Organization Swith Client Level Customizations. It Is Applicable For A Single Unit Organizations, Organization Units At Multiple Locations As Well. ReLATr Software Is A Cloud Baed Architecture, Windows Based, Secure And Scalable Without Limitations.</p>
								</div>
							</div>

							<div className="col-md-5 col-xs-12 ctnimgWrap " style={{paddingTop: "40px" }} data-aos="fade-left" >
								<img src={Pdt03Img} alt="Gap Analysis" className="ctnimg" style = {{width: "80%" }} />
							</div>
							
						</div>
					</div>
				</div>






			</div>

			<FooterNav />
		</div>
	)
}
export default ProductsPage;