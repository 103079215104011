import React,{useEffect} 	from "react";
import Navbar 				from './global/navbar';
import FooterNav 			from './global/FooterNav';
import carbannImg 			from "../assets/img/bann20.jpg";
import comingSoonImg 		from "../assets/img//coming-soon-2.png"

const CareerPage = () => {

	let svgTag = '<defs><path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" /></defs><g class="col-md-12 col-xs-12 parallax"><use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" /><use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" /><use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" /><use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" /></g>';

	useEffect(() => {
	    document.title = 'Careers';
	  }, []);

	return(


		<div className = "col-md-12 col-xs-12 dcol">
			<Navbar />
			<div className = "col-md-12 col-xs-12 dcol" style = {{marginTop : '60px' }} >
				<div className="col-md-12 col-xs-12 drow mhbanner_wrap careerPageBann" style={{ backgroundImage: `url(${carbannImg})`, minHeight: '230px', position : 'relative' }} >
					<div className="container">
						<div className="col-md-10 col-xs-12 mhbanner_ctn mxsbanner_ctn">
							<h3 style={{fontSize: "30px"}}>Careers</h3>
							
						</div>
					</div>
					<div className="wavesWrap wavesMini">
					    <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto"   dangerouslySetInnerHTML={{__html: svgTag }} >
						</svg>
					</div>
				</div>
				<div className="col-md-12 col-xs-12 pagexsHeader">
					<h3>Career</h3>
				</div>

				<div className="col-md-12 col-xs-12 drow ctnbox_wrap" >
					<div className="container">
						<div className="col-md-10 col-md-offset-1 col-xs-12 ctnwrapper" style = {{padding : '15px' }}>
							<div className="col-md-12 col-xs-12 dcol ctnheaderWrap">
								<h3 style = {{marginTop : '0px' }} className="ctnheader text-left aos-init aos-animate" data-aos="zoom-in">Java Developer <span style = {{ color : '#000' }}> ( Location : - Inverness, FL ) </span></h3>
							</div>
							<div className="col-md-12 col-xs-12 ctnbodyWrap text-left" style = {{padding : '0px' }}>
								<p className="ctnpara ctnsmpara " data-aos="zoom-in">
									Participate in requirement analysis, collaborating with the other teams and gathering functional & non-functional requirements, and then involving the product manager to plan and create the stories
								</p>
								<p className="ctnpara ctnsmpara" data-aos="zoom-in">Develop and maintain Java-based web applications using the Spring Boot framework.</p>
								<p className="ctnpara ctnsmpara" data-aos="zoom-in">Develop RESTful APIs (using Spring Boot framework) and implemented them for seamless integration between different software components</p>
								<p className="ctnpara ctnsmpara" data-aos="zoom-in">Write clean, reusable, and scalable code in Java using Object oriented concepts & SOLID principles</p>
								<p className="ctnpara ctnsmpara" data-aos="zoom-in">Conduct unit testing, integration testing, and resolved bugs to ensure application reliability and quality.</p>
								<p className="ctnpara ctnsmpara" data-aos="zoom-in">Work extensive  in Java, J2EE technologies such as Servlets, JSP, Struts, Spring, Hibernate, Swings, Applets, JSF, EJB, JPA, RMI, JMS, JNDI, Ibatis, Web Services, JMS, JSTL, JDBC, Multi-Threading, etc.</p>
								<p className="ctnpara ctnsmpara" data-aos="zoom-in">Work on XML technologies like XML, XSL, XSD, XSLT, XQuery, XPath and DTD in Using a Microservice architecture, with Spring Boot-based services interacting through REST and Apache Kafka.</p>
								<p className="ctnpara ctnsmpara" data-aos="zoom-in">Work on  bash and Python scripting with focus on DevOps tools, CI/CD, and AWS Cloud Architecture configuring and deploying the applications into Tomcat, Nginx, Web Sphere, Web Logic, and J boss.</p>
								<p className="ctnpara ctnsmpara" data-aos="zoom-in">Manage Data Access Object (DAO) case to display a reflection layer between the Business reason level (Business address) and the Persistent stockpiling level (data source).</p>
								<p className="ctnpara ctnsmpara" data-aos="zoom-in">Develop the web/enterprise applications using Software Development Lifecycle (SDLC) methodologies including the design programming, testing and implementation using Java/J2ee technologies.</p>
								<p className="ctnpara ctnsmpara" data-aos="zoom-in">Develop applications using Core java concepts such as OOP concepts, collections Framework, Exception Handling, Multithreading and JDBC</p>
								<p className="ctnpara ctnsmpara" data-aos="zoom-in"> Must have a Bachelor Degree or Equivalent Specialized in Computer Science or related field.</p>
								<p className="ctnpara ctnsmpara" data-aos="zoom-in">Send your resume to: <span style = {{ textTransform : 'lowercase' }} >usa@swantechsolution.com</span></p>

							</div>
						</div>
					</div>
				</div>



				<div className="col-md-12 col-xs-12 drow ctnbox_wrap" style = {{backgroundColor : '#FFF' }} >
					<div className="container">
						<div className="col-md-10 col-md-offset-1 col-xs-12 ctnwrapper" style = {{padding : '15px' }}>
							<div className="col-md-12 col-xs-12 dcol ctnheaderWrap">
								<h3 style = {{marginTop : '0px' }} className="ctnheader text-left aos-init aos-animate" data-aos="zoom-in">Python Developer <span style = {{ color : '#000' }}> ( Location : - Inverness, FL ) </span></h3>
							</div>
							<div className="col-md-12 col-xs-12 ctnbodyWrap text-left" style = {{padding : '0px' }}>
								<p className="ctnpara ctnsmpara " data-aos="zoom-in">Work on Engineering Simulation Software Products (CFD/CAE), Numerical methods, Simulation and Analysis, Testing for Functionality, Accuracy, Performance, Usability and Design improvements, writing scripts in Python and Perl, Parallel processing, leading testing activities.</p>
								<p className="ctnpara ctnsmpara " data-aos="zoom-in">Build and Productionizing entire Machine Learning pipeline, Creation of REST API for all the AI/ML projects, testing of APIs using Postman, Creation of Docker images/containers and deployment of the AI/ML projects on servers and also cloud platforms, migration of AI/ML services from one server to another server, performance improvements of AI/ML services.</p>
								<p className="ctnpara ctnsmpara " data-aos="zoom-in">Work on several end-to-end AI/Machine-Learning projects with Python, Machine Learning, Deep Learning, Computer Vision, Optical Character Recognition (OCR), Data Science for building products for Cyber Security and Banking applications, building new products.</p>
								<p className="ctnpara ctnsmpara " data-aos="zoom-in">Work closely with Product and Project Managers, Product Designers, QA team, and DevOps to drive engineering releases.</p>
								<p className="ctnpara ctnsmpara " data-aos="zoom-in">Analyze the logs using Splunk and app health such as memory leaks, API and SQL query performance using the AppDynamics tool as a part of application monitoring.</p>
								<p className="ctnpara ctnsmpara " data-aos="zoom-in">Must have a Bachelor Degree or Equivalent Specialized in Computer Science or related field.</p>
								<p className="ctnpara ctnsmpara " data-aos="zoom-in">Send your resume to: <span style = {{ textTransform : 'lowercase' }} >usa@swantechsolution.com</span></p>
							</div>
						</div>
					</div>
				</div>

				<div className="col-md-12 col-xs-12 drow ctnbox_wrap"  >
					<div className="container">
						<div className="col-md-10 col-md-offset-1 col-xs-12 ctnwrapper" style = {{padding : '15px' }}>
							<div className="col-md-12 col-xs-12 dcol ctnheaderWrap">
								<h3 style = {{marginTop : '0px' }} className="ctnheader text-left aos-init aos-animate" data-aos="zoom-in">BIG Data / Hadoop <span style = {{ color : '#000' }}> ( Location : - Inverness, FL ) </span></h3>
							</div>
							<div className="col-md-12 col-xs-12 ctnbodyWrap text-left" style = {{padding : '0px' }}>
								<p className="ctnpara ctnsmpara " data-aos="zoom-in">Analyzes data requirements, application and processing architectures, data dictionaries, and database and XML schema(s) ,   Develops and delivers innovative business solutions to our stakeholders,       Defines XML Schema and other data requirements to successfully exchange business process document data into our systems , Transfers business documents to data requirements, Troubleshoot issues and recommend solutions Involve in design of data solutions using Hadoop based technologies along with Hadoop, AWS ,   Design and implementation of various Big Data platform,   Components like (Batch Processing, Live Stream Processing. Design and Implemented Data Access Layer, which can connect to various data sources and uses advanced caching techniques to provide fast responses to real time SQL queries using Big Data Technologies.Must have a Bachelor Degree or Equivalent Specialized in Computer Science or related field.</p>
									<p className="ctnpara ctnsmpara " data-aos="zoom-in">Send your resume to: <span style = {{ textTransform : 'lowercase' }} >usa@swantechsolution.com</span></p>
							</div>
						</div>
					</div>
				</div>

				<div className="col-md-12 col-xs-12 drow ctnbox_wrap" style = {{backgroundColor : '#FFF' }} >
					<div className="container">
						<div className="col-md-10 col-md-offset-1 col-xs-12 ctnwrapper" style = {{padding : '15px' }}>
							<div className="col-md-12 col-xs-12 dcol ctnheaderWrap">
								<h3 style = {{marginTop : '0px' }} className="ctnheader text-left aos-init aos-animate" data-aos="zoom-in">Cloud GCP <span style = {{ color : '#000' }}> ( Location : - Inverness, FL ) </span></h3>
							</div>
							<div className="col-md-12 col-xs-12 ctnbodyWrap text-left" style = {{padding : '0px' }}>
								<p className="ctnpara ctnsmpara " data-aos="zoom-in">Create and manage the efficient cloud computing solutions to meet organizational requirements.</p>
								<p className="ctnpara ctnsmpara " data-aos="zoom-in">Interact with the clients for the inconvenience and impacts experienced during the production hours.</p>
								<p className="ctnpara ctnsmpara " data-aos="zoom-in">Assist the customers over Google video Conferencing for live troubleshooting of the issues experiencing to offer better solutions.</p>
								<p className="ctnpara ctnsmpara " data-aos="zoom-in"> Analyze the product limitations and collaborating with the product team to create a feature request for the product improvements.</p>
								<p className="ctnpara ctnsmpara " data-aos="zoom-in">Support the transition of crucial business applications and procedures to public, private and hybrid cloud platforms by providing constant support.</p>
								<p className="ctnpara ctnsmpara " data-aos="zoom-in"> Work with stakeholders to understand the modifications they wish to apply to their current systems, increasing cloud storage capacity to store more files and crucial corporate data.</p>
								<p className="ctnpara ctnsmpara " data-aos="zoom-in">Ensure the protection of data in the customer's environment   by collaborating with security.</p>
								<p className="ctnpara ctnsmpara " data-aos="zoom-in">Must have a Bachelor Degree or Equivalent Specialized in Computer Science or related field.</p>
								<p className="ctnpara ctnsmpara " data-aos="zoom-in">Send your resume to: <span style = {{ textTransform : 'lowercase' }} >usa@swantechsolution.com</span></p>
							</div>
						</div>
					</div>
				</div>

				<div className="col-md-12 col-xs-12 drow ctnbox_wrap"  >
					<div className="container">
						<div className="col-md-10 col-md-offset-1 col-xs-12 ctnwrapper" style = {{padding : '15px' }}>
							<div className="col-md-12 col-xs-12 dcol ctnheaderWrap">
								<h3 style = {{marginTop : '0px' }} className="ctnheader text-left aos-init aos-animate" data-aos="zoom-in">Azure DevOps <span style = {{ color : '#000' }}> ( Location : - Inverness, FL ) ( Position: AZDVPFL ) </span></h3>
							</div>
							<div className="col-md-12 col-xs-12 ctnbodyWrap text-left" style = {{padding : '0px' }}>
								<p className="ctnpara ctnsmpara " data-aos="zoom-in">Design and implement Azure infrastructure services, including virtual machines, storage, networking, and security.</p>
								<p className="ctnpara ctnsmpara " data-aos="zoom-in">Deploy and manage Azure infrastructure services, such as Azure Active Directory, Azure DNS, Azure Traffic Manager, and Azure Firewall.</p>
								<p className="ctnpara ctnsmpara " data-aos="zoom-in"> Monitor and troubleshoot Azure-based solutions, ensuring they are optimized for performance and cost, cloud computing and IT infrastructure, including experience with other cloud platforms such as AWS and Google Cloud, implement Kubernetes-based solutions, including container orchestration, deployment, and management.</p>
								<p className="ctnpara ctnsmpara " data-aos="zoom-in">Create Projects, Branching, Merging, and Access allocation to Users as per organization rules and standards, cloud computing and IT infrastructure, including experience with other cloud platforms such as AWS and Azure.</p>
								<p className="ctnpara ctnsmpara " data-aos="zoom-in">Create of Repos and managing files in repos for Build & release, managing of Version control for the application, CI/CD Pipeline in Azure DevOps for the .net, and UI based Web Applications and API.</p>
								<p className="ctnpara ctnsmpara " data-aos="zoom-in">Configure availability set, and Load Balancing set as per the project requirement, create multiple environments like DEV, QA, UAT, and NON- PROD various releases and designed.</p>
								<p className="ctnpara ctnsmpara " data-aos="zoom-in"> Set up and maintain the Kubernetes cluster infrastructure - includes configuring the hardware and networking, installing and configuring Kubernetes components, and managing access controls and security policies.</p>
								<p className="ctnpara ctnsmpara " data-aos="zoom-in">Must have a Bachelor Degree or Equivalent Specialized in Computer Science or related field.</p>
									<p className="ctnpara ctnsmpara " data-aos="zoom-in">Send your resume to: <span style = {{ textTransform : 'lowercase' }} >usa@swantechsolution.com</span></p>
							</div>
						</div>
					</div>
				</div>

				<div className="col-md-12 col-xs-12 drow ctnbox_wrap" style = {{backgroundColor : '#fff'}} >
					<div className="container">
						<div className="col-md-10 col-md-offset-1 col-xs-12 ctnwrapper" style = {{padding : '15px' }}>
							<div className="col-md-12 col-xs-12 dcol ctnheaderWrap">
								<h3 style = {{marginTop : '0px' }} className="ctnheader text-left aos-init aos-animate" data-aos="zoom-in">AWS Linux <span style = {{ color : '#000' }}> ( Location : - Inverness, FL ) ( Position: AWZLNXFL ) </span></h3>
							</div>
							<div className="col-md-12 col-xs-12 ctnbodyWrap text-left" style = {{padding : '0px' }}>
								<p className="ctnpara ctnsmpara " data-aos="zoom-in"> Work on  RHEL administration with AWS and Devops , AWS Cloud platform and its features which includes   EC2,VPC,EBS,AMI,CloudWatch,Autoscaling,S3 and Route53</p>
								<p className="ctnpara ctnsmpara " data-aos="zoom-in">Implement Amazon EC2 setting up instances and security groups with cloud formations.</p>
								<p className="ctnpara ctnsmpara " data-aos="zoom-in">Responsible for Installation, configuration, upgrading, patching and administration of RHEL 7.x/6.x Servers.</p>
								<p className="ctnpara ctnsmpara " data-aos="zoom-in">Create Alarms and trigger points in cloud watch based on thresholds and monitored the server's Performance, CPU Utilization, and    disk usage,  S3 bucket with restricted policies, set up storage in AWS using S3 bucket ,   AMIs of instances and snapshots from volumes, on version control system GIT ,   Continuous Integration(CI) and continuous Delivery using Jenkins , Ansible along with schedule   different Jobs for taking backup by using Crontab</p>
								<p className="ctnpara ctnsmpara " data-aos="zoom-in">Configure the public and private Subnets on VPC,    Package Management Using RPM and YUM Troubleshoot Splunk Agent,Nessus Agent and Crowd strike Agent connectivity issues</p>
								<p className="ctnpara ctnsmpara " data-aos="zoom-in"> Monitor tools PS,TOP,SAR,IOSTAT,NETSTAT,VMSTAT,   LVM , Automation Patching   with SSM service in AWS</p>
								<p className="ctnpara ctnsmpara " data-aos="zoom-in">Must have a Bachelor Degree or Equivalent Specialized in Computer Science or related field.</p>
									<p className="ctnpara ctnsmpara " data-aos="zoom-in">Send your resume to: <span style = {{ textTransform : 'lowercase' }} >usa@swantechsolution.com</span></p>
							</div>
						</div>
					</div>
				</div>

				<div className="col-md-12 col-xs-12 drow ctnbox_wrap"  >
					<div className="container">
						<div className="col-md-10 col-md-offset-1 col-xs-12 ctnwrapper" style = {{padding : '15px' }}>
							<div className="col-md-12 col-xs-12 dcol ctnheaderWrap">
								<h3 style = {{marginTop : '0px' }} className="ctnheader text-left aos-init aos-animate" data-aos="zoom-in">Quality  Performance / SDET <span style = {{ color : '#000' }}> ( Location : - Inverness, FL ) ( Position: QAFL ) </span></h3>
							</div>
							<div className="col-md-12 col-xs-12 ctnbodyWrap text-left" style = {{padding : '0px' }}>
								<p className="ctnpara ctnsmpara " data-aos="zoom-in">Perform load tests to validate system performance and stability, analyze test results and work with Developers and Engineers to perform bug fixes,   Provide technical assistance to improve system performance, capacity, reliability and scalability, perform root cause analysis of performance issues and suggest corrective actions.</p>
								<p className="ctnpara ctnsmpara " data-aos="zoom-in"> Oversee system performance life cycle and identify key metrics for performance improvements, evaluate system performances and recommend improvements.</p>
								<p className="ctnpara ctnsmpara " data-aos="zoom-in">Generate periodic performance reports for management review and d evelop , maintain performance metrics to measure system performance.</p>
								<p className="ctnpara ctnsmpara " data-aos="zoom-in">Evaluate hardware to determine if it can effectively meet current needs as well as anticipated future needs.</p>
								<p className="ctnpara ctnsmpara " data-aos="zoom-in">Analyze data from various sources including network traffic, server logs, application logs, and security event logs.</p>
								<p className="ctnpara ctnsmpara " data-aos="zoom-in">Monitor the performance of networks, databases, applications, or other computer systems, requirements gathering, POC, performance test plan,   workload model, test data preparation, execution and analyzing the reports.</p>
								<p className="ctnpara ctnsmpara " data-aos="zoom-in">Conduct different load tests,  analyzed test results and  identified bottlenecks, Plan activities, tasks assigned to the team and tracking the status of the project, raise the defects through Test management   tools like Confluence, JIRA, ALM, QC and Test Director.</p>
								<p className="ctnpara ctnsmpara " data-aos="zoom-in"> Must have a Bachelor Degree or Equivalent Specialized in Computer Science or related field</p>
								<p className="ctnpara ctnsmpara " data-aos="zoom-in">Send your resume to: <span style = {{ textTransform : 'lowercase' }} >usa@swantechsolution.com</span></p>
							</div>
						</div>
					</div>
				</div>

				<div className="col-md-12 col-xs-12 drow ctnbox_wrap" style = {{ backgroundColor : '#fff' }}  >
					<div className="container">
						<div className="col-md-10 col-md-offset-1 col-xs-12 ctnwrapper" style = {{padding : '15px' }}>
							<div className="col-md-12 col-xs-12 dcol ctnheaderWrap">
								<h3 style = {{marginTop : '0px' }} className="ctnheader text-left aos-init aos-animate" data-aos="zoom-in">Full Stack Developer <span style = {{ color : '#000' }}> ( Location : - Inverness, FL ) ( Position: FSFL ) </span></h3>
							</div>
							<div className="col-md-12 col-xs-12 ctnbodyWrap text-left" style = {{padding : '0px' }}>
								<p className="ctnpara ctnsmpara " data-aos="zoom-in">Collaborate with cross-functional teams to design, develop, and implement new features for full-stack web application, contribute to the analysis of system requirements and aid in prioritizing tasks based on project objectives.</p>
								<p className="ctnpara ctnsmpara " data-aos="zoom-in">Participate in the development of dynamic web applications using technologies such as React, Redux, Node.js, and Express.</p>
								<p className="ctnpara ctnsmpara " data-aos="zoom-in">Contribute to developing and maintaining robust APIs and back-end services using technologies such as Node.js, Express, and GraphQL.</p>
								<p className="ctnpara ctnsmpara " data-aos="zoom-in">Develop quality software solutions that add business value while minimizing negative impacts on performance and uptime</p>
								<p className="ctnpara ctnsmpara " data-aos="zoom-in"> Work with SQL, Big Data, or NoSQL databases to generate data-driven insights and reports.</p>
								<p className="ctnpara ctnsmpara " data-aos="zoom-in">Collaborate with QA teams to develop test plans and ensure adherence to quality standards.</p>
								<p className="ctnpara ctnsmpara " data-aos="zoom-in">Coordinate with DevOps teams to ensure smooth application deployment, effective monitoring, and maintenance in cloud environments.</p>
								<p className="ctnpara ctnsmpara " data-aos="zoom-in">Participate in problem-solving and troubleshooting sessions to address application performance, scalability, and user experience issues.</p>
								<p className="ctnpara ctnsmpara " data-aos="zoom-in">Must have a Bachelor Degree or Equivalent Specialized in Computer Science or related field.</p>
									<p className="ctnpara ctnsmpara " data-aos="zoom-in">Send your resume to: <span style = {{ textTransform : 'lowercase' }} >usa@swantechsolution.com</span></p>
							</div>
						</div>
					</div>
				</div>


			</div>

			<FooterNav />
		</div>
	)
}
export default CareerPage;