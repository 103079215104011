import React,{useEffect} from "react";
import Navbar 		from './global/navbar';
import FooterNav 	from './global/FooterNav';

import pageBanner from "../assets/img/bann02.JPG";

const DeliveryInnovation = () => {

	let svgTag = '<defs><path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" /></defs><g class="col-md-12 col-xs-12 parallax"><use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" /><use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" /><use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" /><use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" /></g>';

	useEffect(() => {
	    document.title = 'Delivery Innovation';
	  }, []);

	return(


		<div className = "col-md-12 col-xs-12 dcol">
			<Navbar />
			<div className = "col-md-12 col-xs-12 dcol" style = {{marginTop : '60px' }} >

				<div className="col-md-12 col-xs-12 drow mhbanner_wrap deliveryInvoBann" style={{ backgroundImage: `url(${pageBanner})`, minHeight: '230px', position : 'relative' }} >
					<div className="container">
						<div className="col-md-6 col-xs-12 mhbanner_ctn mxsbanner_ctn">
							<h3 style={{fontSize: "30px"}}><b style={{color: "#fff"}}>Delivery & Innovation </b></h3>
							
						</div>
					</div>
					<div className="wavesWrap wavesMini">
					    <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto"   dangerouslySetInnerHTML={{__html: svgTag }} >
						</svg>
					</div>
				</div>
				<div className="col-md-12 col-xs-12 pagexsHeader">
					<h3>Delivery & Innovation</h3>
				</div>

				<div className="col-md-12 col-xs-12 drow ctnbox_wrap" style={{padding: "50px 0px", backgroundColor: "#fff"}}>
					<div className="container">
						<div className="col-md-12 col-xs-12 ctnwrapper">

							<div className="col-md-6 col-xs-12 ctnstru dcol "  data-aos="zoom-in" style={{padding: "20px 0px 0px 0px"}}>
								
								<div className="col-md-10 col-md-offset-1 col-xs-12 ctnbodyWrap text-center" style={{padding: "10px"}}>
									<p className="ctnpara">
										The Offshore Delivery Center provides a unique place for development and innovation. Our team of developers is working to develop new solutions that will advance enterprises into the future. They work with a variety of new emerging technologies and methodologies such as:
									</p>
								</div>
							</div>
							<div className="col-md-5 col-md-offset-1 col-xs-12 ctnListWrap" style={{padding: "16px 0px 0px 0px"}}>
								<ul>
									<li  data-aos="zoom-in">
										
										<span className="delbgColor" > Artificial Intelligence (AI)</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="delbgColor" > Data Sciences </span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="delbgColor" > Machine Learning (ML)</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="delbgColor" > Mobile Apps</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="delbgColor" > Robotic Process Automation (RPA)</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="delbgColor" > ServiceNow (SNOW)</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="delbgColor" > Splunk</span>
									</li>
								</ul>
							</div>
							
						</div>
					</div>
				</div>

			</div>
			<FooterNav />
		</div>
	)

}

export default DeliveryInnovation;