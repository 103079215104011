import React,{useEffect} from "react";
import Navbar 		from './global/navbar';
import FooterNav 	from './global/FooterNav';

import pageBan9 from "../assets/img/bann09.jpg";

const RpoServices = () => {

	let svgTag = '<defs><path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" /></defs><g class="col-md-12 col-xs-12 parallax"><use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" /><use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" /><use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" /><use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" /></g>';

	useEffect(() => {
	    document.title = 'RPO Services';
	  }, []);

	return(

		<div className = "col-md-12 col-xs-12 dcol">
			<Navbar />
			<div className = "col-md-12 col-xs-12 dcol" style = {{marginTop : '60px' }} >
				<div className="col-md-12 col-xs-12 drow mhbanner_wrap deliveryInvoBann" style={{ backgroundImage: `url(${pageBan9})`, minHeight: '230px', position : 'relative' }} >
					<div className="container">
						<div className="col-md-9 col-xs-12 mhbanner_ctn rpobannerWrap">
							<h3 style={{fontSize: "30px"}}>RPO Services</h3>
							
						</div>
					</div>
					<div className="wavesWrap wavesMini">
					    <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto"   dangerouslySetInnerHTML={{__html: svgTag }} >
						</svg>
					</div>
				</div>
				<div className="col-md-12 col-xs-12 pagexsHeader">
					<h3>RPO Services</h3>
				</div>

				<div className="col-md-12 col-xs-12 drow ctnbox_wrap" style={{backgroundColor: "#fff", padding: "50px 0px" }}>
					<div className="container">
						<div className="col-md-8 col-md-offset-2 col-xs-12 ctnwrapper">
							<div className="col-md-12 col-xs-12 ctnbodyWrap text-center">
								<p className="ctnpara " data-aos="zoom-in">
									With its RPO Services, SWANTECH makes sure that each resume is systematically screened. That includes screening resumes sourced from online portals, search engines, emails, and various job networks on the internet. We have large databases and latest technologies for updating it in the most orderly manner. 
								</p>
								
							</div>
						</div>
					</div>
				</div>

				<div className="col-md-12 col-xs-12 drow ctnbox_wrap" >
					<div className="container">
						<div className="col-md-10 col-md-offset-1 col-xs-12 ctnwrapper">
							<div className="col-md-12 col-xs-12 ctnbodyWrap text-center">
								<p className="ctnpara" data-aos="zoom-in">
									SWANTECH provides Recruitment Process Outsourcing services to companies across all industry verticals, thus making sure we have candidates with varied skill-sets. This helps us expand our reach beyond that of RPO companies employing traditional recruiting practices. 
								</p>
								
							</div>
						</div>
					</div>
				</div>

				<div className="col-md-12 col-xs-12 drow ctnbox_wrap" style={{backgroundColor: "#fff" }}>
					<div className="container">
						<div className="col-md-10 col-md-offset-1 col-xs-12 ctnwrapper">
							<div className="col-md-12 col-xs-12 ctnbodyWrap text-center">
								<p className="ctnpara" data-aos="zoom-in">
									With its aim to attract the top human capital, SWANTECH nominates candidates that have the best credentials and the right blend of skills, knowledge and experience. The aim at SWANTECH is that the personnel you would be taking onboard fits your specific requirements and exceeds expectations in whatever role you assign them with. 
								</p>
								
							</div>
						</div>
					</div>
				</div>

				<div className="col-md-12 col-xs-12 drow ctnbox_wrap" >
					<div className="container">
						<div className="col-md-10 col-md-offset-1 col-xs-12 ctnwrapper">
							<div className="col-md-12 col-xs-12 ctnbodyWrap text-center">
								<p className="ctnpara "  data-aos="zoom-in">
									SWANTECH’s RPO Service Is Carried out by:
								</p>
							</div>
							<div className="col-md-12  col-xs-12 ctnListWrap ctnListHalf" style={{padding: "5px 0px 0px 0px"}}>
								<ul>
									<li  data-aos="zoom-in">
										
										<span className="rpobgColor" > Comprehensive research on professionals working in managerial Positions</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="rpobgColor" > Conducting interview at university campuses, organizing job fairs, etc.</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="rpobgColor" > Contingency Search</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="rpobgColor" > Campus Placements</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>

				<div className="col-md-12 col-xs-12 drow ctnbox_wrap" style={{backgroundColor: "#fff"}}>
					<div className="container">
						<div className="col-md-10 col-md-offset-1 col-xs-12 ctnwrapper">
							<div className="col-md-12 col-xs-12 ctnbodyWrap text-center">
								<p className="ctnpara " data-aos="zoom-in">
									SWANTECH makes sure it always goes an extra mile by offering you candidates who possess the requisite qualifications and experience, and at the same time a zeal for delivering precision. 
								</p>
								
							</div>
						</div>
					</div>
				</div>

			</div>
			<FooterNav />
		</div>

	)
}

export default RpoServices;