//import React from 'react';

import React, { useEffect, useState } from "react";

import { Outlet, Link } from "react-router-dom";


import '../../assets/css/commonStyle.css';
import '../../assets/css/navbarStyle.css';

import clogo from '../../assets/img/navbar-logo.png';

const Navbar = () => {

	const [showNav, setActive] = useState("false");
  	const ToggleClass = () => {
    	setActive(!showNav); 
   	};

	return(
		<div className="col-md-12 col-xs-12 navbar navbar-default mnavbar">

			<div className="container">
		      	<div className="col-md-12 col-xs-12 tmnavbar">
			        <div className="navbar-header">
			        	<button type="button" onClick={ToggleClass} className="navbar-toggle toggle_btn" id="right_togglebar" name="sidenav_toggle" bb="#navbar_collapse" data-toggle="collapse" data-target="">
							<span className="icon-bar"></span>
							<span className="icon-bar"></span>
							<span className="icon-bar"></span>                        
						</button>
				        <Link to="/" className="navbar-brand mnavbar-brand">
				          	
				          	<img alt="mnavbar-logo" src={clogo} className="mnavbar-logo" />
				        </Link>
			        </div>
			        <div  className="mnavSidebar_menu d_none rsidebar" id={showNav ? "navIn" : "navOut"} >
			        	<ul className="nav navbar-nav navbar-right mnavbar-nav">
			        		<li>
					          <Link to="/" className="flia" >Home</Link>
				          	</li>
				          	<li className="dropdown">
				            	<a href="#" data-toggle="dropdown" className="flia fliuinfa dropdown-toggle" >
				              		About Us
				              		<span className="caret"></span>
				           		</a>
				            	<ul className="dropdown-menu wow zoomIn animated jnav_dropmenu" >
				              		<li><Link to="/delivery-innovation" >Delivery & Innovation</Link></li>
				              		<li><Link to="/global-delivery" >Global Delivery</Link></li>
				            	</ul>
				          	</li>
				          	<li className="dropdown">
				            	<a href="#" data-toggle="dropdown" className="flia fliuinfa dropdown-toggle" >
				              		Services
				              		<span className="caret"></span>
				            	</a>
				            	<ul className="dropdown-menu wow zoomIn animated jnav_dropmenu" style={{minWidth: '250px',left: '-48%'}} >
					              <li>
					              	<Link to="/custom-software-development">Custom Software Development</Link>
					              </li>
					              <li>
					              	<Link to="/solutions-system-integration">Solutions & System Integration</Link>
					              </li>
					              <li>
					              	<Link to="/quality-assurance">Quality Assurance  </Link>
					              </li>
					              <li>
					              	<Link to="/staff-augmentation">Staff Augmentation  </Link>
					              </li>
					              <li>
					              	<Link to="/rpo">RPO </Link>
					              </li>
					              <li>
					              	<Link to="/hr-outsourcing">HR Outsourcing  </Link>
					              </li>
				            	</ul>
				          	</li>
				          	<li className="dropdown">
					            <a href="#" data-toggle="dropdown" className="flia fliuinfa dropdown-toggle" >
					              Competencies
					              <span className="caret"></span>
					            </a>
					            <ul className="dropdown-menu wow zoomIn animated jnav_dropmenu" style={{left: '-4%'}} >
					              <li><Link to="/devOps" >DevOps </Link></li>
					              <li><Link to="/web-technologies" >Web Technologies </Link></li>
					              <li><Link to="/datawarehousing" >Datawarehousing</Link></li>
					              <li><Link to="/quality-assurance" >Quality Assurance</Link></li>
					              <li><Link to="/cloud-computing" >Cloud Computing</Link></li>
					              <li><Link to="/analytics" >Analytics</Link></li>
					              <li><Link to="/databases-services" >Databases</Link></li>
					              <li><Link to="/enterprise-mobility" >Enterprise Mobility</Link></li>
					            </ul>
				          	</li>
				          	<li>
					          <Link to="/products" className="flia" >
					            Products
					          </Link>
				          	</li>
				          	<li>
					          <Link to="/career" className="flia" >
					            Careers
					          </Link>
				          	</li>
				          	<li>
					          <Link to="/contact-us" className="flia" >
					            Contact Us
					          </Link>
				          	</li>
			        	</ul>
			        </div>
		    	</div>
		    </div>
		</div>
	)

	
}
export default Navbar;