import React,{useEffect} from "react";
import Navbar 		from './global/navbar';
import FooterNav 	from './global/FooterNav';

import clouldCompBann from "../assets/img/bann15.JPG";

const CloudComputing = () => {

	let svgTag = '<defs><path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" /></defs><g class="col-md-12 col-xs-12 parallax"><use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" /><use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" /><use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" /><use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" /></g>';

	useEffect(() => {
	    document.title = 'Cloud Computing';
	  }, []);
	return(
		<div className = "col-md-12 col-xs-12 dcol">
			<Navbar />
			<div className = "col-md-12 col-xs-12 dcol" style = {{marginTop : '60px' }} >

				<div className="col-md-12 col-xs-12 drow mhbanner_wrap cloudCompBann" style={{ backgroundImage: `url(${clouldCompBann})`, minHeight: '230px', position : 'relative' }} >
					<div className="container">
						<div className="col-md-10 col-xs-12 mhbanner_ctn mxsbanner_ctn">
							<h3 style={{fontSize: "30px"}}><b style={{color: "#fff"}}>Cloud Computing </b></h3>
							
						</div>
					</div>
					<div className="wavesWrap wavesMini">
					    <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto"   dangerouslySetInnerHTML={{__html: svgTag }} >
						</svg>
					</div>
				</div>
				<div className="col-md-12 col-xs-12 pagexsHeader">
					<h3>Cloud Computing</h3>
				</div>

				<div className="col-md-12 col-xs-12 drow ctnbox_wrap" style={{backgroundColor: "#fff" }} >
					<div className="container">
						<div className="col-md-10 col-md-offset-1 col-xs-12 ctnwrapper">
							<div className="col-md-12 col-xs-12 ctnheaderWrap">
								<h3 className="ctnheader text-center " data-aos="zoom-in" >
									Competencies
								</h3>
							</div>
							<div className="col-md-12 col-xs-12 ctnListWrap ctnListHalf" style={{padding: "30px 0px 0px 0px" }}>
								<ul>
									<li  data-aos="zoom-in">
										
										<span className="databgColor" > Application Development Software.</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="databgColor" > Data Access, Analysis, and Delivery</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="databgColor" > Information & Data Management</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="databgColor" > Integration & Process Automation Middleware</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="databgColor" > Quality & Life-Cycle Tools</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="databgColor" > Enterprise Portals</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="databgColor" > System and Network Management Software</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="databgColor" > Collaborative Applications & Content Applications</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="databgColor" > Enterprise Resource Management Applications</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="databgColor" > Supply Chain Management Applications</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="databgColor" > Operations and Manufacturing Applications</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="databgColor" > Engineering Applications and Customer Relationship.</span>
									</li>
									<li  data-aos="zoom-in" style={{width: "100%" }}>
										
										<span className="databgColor" > Professional consultation and support for Amazon Web Services (AWS), Rackspace, Windows Azure, Digital Ocean, and Salesforce.com</span>
									</li>

								</ul>
							</div>
						</div>
					</div>
				</div>

				<div className="col-md-12 col-xs-12 drow ctnbox_wrap">
					<div className="container">
						<div className="col-md-10 col-md-offset-1 col-xs-12 ctnwrapper">
							<div className="col-md-12 col-xs-12 ctnheaderWrap">
								<h3 className="ctnheader text-center " data-aos="zoom-in" >
									Advantages
								</h3>
							</div>
							<div className="col-md-12 col-xs-12 ctnListWrap ctnListHalf" style={{ padding: "30px 0px 0px 0px" }}>
								<ul>
									<li  data-aos="zoom-in">
										
										<span className="databgColor" > Transforming to “demand driven” IT organization.</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="databgColor" > Empowering IT for serving to business agility.</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="databgColor" > Cloud Adoption Services</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="databgColor" > Cloud Adoption Product Engineering Services.</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="databgColor" > Cloud porting Services.</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="databgColor" > Cloud IT governance and management.</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="databgColor" > Cloud assessment services.</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="databgColor" > Manage the technology fragmentation within your IT landscape more effectively.</span>
									</li>
									
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			<FooterNav />
		</div>
	)
}
export default CloudComputing;