import React,{useEffect} from "react";
import Navbar 		from './global/navbar';
import FooterNav 	from './global/FooterNav';

import webtechBann from "../assets/img/bann13.jpg";

const WebTechnologies = () => {

	let svgTag = '<defs><path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" /></defs><g class="col-md-12 col-xs-12 parallax"><use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" /><use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" /><use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" /><use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" /></g>';

	useEffect(() => {
	    document.title = 'Web Technologies';
	  }, []);

	return(


		<div className = "col-md-12 col-xs-12 dcol">
			<Navbar />
			<div className = "col-md-12 col-xs-12 dcol" style = {{marginTop : '60px' }} >

				<div className="col-md-12 col-xs-12 drow mhbanner_wrap wentechbann" style={{ backgroundImage: `url(${webtechBann})`, minHeight: '230px', position : 'relative', backgroundSize: "contain" }} >
					<div className="container">
						<div className="col-md-8 col-xs-12 mhbanner_ctn mxsbanner_ctn">
							<h3 style={{fontSize: "30px"}}>Web Technologies </h3>
							
						</div>
					</div>
					
				</div>
				<div className="col-md-12 col-xs-12 pagexsHeader">
					<h3>Web Technologies</h3>
				</div>

				<div className="col-md-12 col-xs-12 drow ctnbox_wrap" style={{backgroundColor: "#fff" }}>
					<div className="container">
						<div className="col-md-10 col-md-offset-1 col-xs-12 ctnwrapper">
							<div className="col-md-12 col-xs-12 ctnbodyWrap text-center">
								<p className="ctnpara" data-aos="zoom-in">
									<b className="txtHighlight">SWANTECH’s development team </b> represents years of software development experience serving a wide range of industries. We combine individual expertise with the best available technology to offer the most valuable custom software solutions. A successful application reflects the unique and subtle requirements of your business. With experience entering a range of industries, and the skill to ask the right questions, SWANTECH’s developers work to understand exactly what the enterprise needs. A custom application and a development process that serve the business purpose. SWANTECH’s development team works with the clients, flexing with the corporate culture, meeting the deadlines, and communicating clearly every step of the way. These qualities amount to a valuable custom software application and a valuable development partner who will serve your business. 
								</p>
							</div>
						</div>
					</div>
				</div>

				<div className="col-md-12 col-xs-12 drow ctnbox_wrap" style={{backgroundColor: "#fff" }}>
					<div className="container">
						<div className="col-md-10 col-md-offset-1 col-xs-12 ctnwrapper">
							<div className="col-md-12 col-xs-12 ctnheaderWrap">
								<h3 className="ctnheader text-center " data-aos="zoom-in" >
									Custom Software Development Services
								</h3>
							</div>
							<div className="col-md-12 col-xs-12 ctnbodyWrap text-center">
								<p className="ctnpara " data-aos="zoom-in">
									Custom Web applications, Custom business applications, Database-driven Web sites, Custom database applications & Web portals 
								</p>
								<p className="ctnpara " data-aos="zoom-in">
									Our areas of expertise in Web Technologies include:
								</p>
							</div>
							<div className="col-md-12 col-xs-12 ctnListWrap ctnListHalf" style={{padding: "30px 0px 0px 0px" }}>
								<ul>
									<li  data-aos="zoom-in">
										
										<span className="webbgColor" > Microsoft .NET Platform</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="webbgColor" > J2EE Technologies/PHP Technologies</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="webbgColor" > IBM Websphere/Weblogic Technologies</span>
									</li>
								</ul>
							</div>
							<div className="col-md-12 col-xs-12 ctnbodyWrap text-center">
								<p className="ctnpara " data-aos="zoom-in">
									SWANTECH’s Microsoft Certified Professionals are well-trained and highly skilled IT professionals in the business. Not only do our consultants receive special access to technical support for the entire Microsoft Product line, but also they receive early exposure to, and firsthand experience with, new product releases. Through this knowledge, we are able to help our customers maximize their benefit from their investment in the Microsoft product line 
								</p>
							</div>
						</div>
					</div>
				</div>

			</div>
			<FooterNav />
		</div>
	)
} 
export default WebTechnologies;