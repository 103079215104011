import React,{useEffect} from "react";
import Navbar 		from './global/navbar';
import FooterNav 	from './global/FooterNav';

import pageBann6 from "../assets/img/bann06.JPG";

const SolutionsSystemIntegration = () => {

	let svgTag = '<defs><path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" /></defs><g class="col-md-12 col-xs-12 parallax"><use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" /><use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" /><use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" /><use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" /></g>';

	useEffect(() => {
	    document.title = 'Solutions - System Integration';
	  }, []);

	return(
		<div className = "col-md-12 col-xs-12 dcol">
			<Navbar />
			<div className = "col-md-12 col-xs-12 dcol" style = {{marginTop : '60px' }} >
				<div className="col-md-12 col-xs-12 drow mhbanner_wrap ssystemBann" style={{ backgroundImage: `url(${pageBann6})`, minHeight: '230px', position : 'relative' }} >
					<div className="container">
						<div className="col-md-9 col-xs-12 mhbanner_ctn mxsbanner_ctn">
							<h3 style={{fontSize: "30px"}}><b style={{color: "#fff"}}>Solutions & System Integration </b></h3>
							
						</div>
					</div>
					<div className="wavesWrap wavesMini">
					    <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto"   dangerouslySetInnerHTML={{__html: svgTag }} >
						</svg>
					</div>
				</div>
				<div className="col-md-12 col-xs-12 pagexsHeader">
					<h3>Solutions & System Integration</h3>
				</div>

				<div className="col-md-12 col-xs-12 drow ctnbox_wrap" style={{padding: '70px 15px 50px 15px', backgroundColor: '#fff' }}>
					<div className="container">
						<div className="col-md-8 col-md-offset-2 col-xs-12 ctnwrapper">
							<div className="col-md-12 col-xs-12 ctnbodyWrap text-center">
								<p className="ctnpara " data-aos="zoom-in">
									We design, develop and implement reliable applications that run securely on internal networks and over the Internet. We have successfully completed 100% of our projects on time and on budget. 
								</p>
								
							</div>
						</div>
					</div>
				</div>

				<div className="col-md-12 col-xs-12 drow ctnbox_wrap" style={{padding: '50px 0px' }}>
					<div className="container">
						<div className="col-md-12 col-xs-12 ctnwrapper">

							<div className="col-md-7 col-xs-12 ctnstru dcol" style={{padding: '20px 0px 0px 0px' }}>
								
								<div className="col-md-10 col-md-offset-1 col-xs-12 ctnbodyWrap text-center" style={{padding: "10px"}}>
									<p className="ctnpara  " data-aos="fade-right" >
										Our core competencies are in the areas of custom application development, service oriented architecture, web services, and privacy and security. We offer a range of IT services, from tactical engagements to full project lifecycle solutions that enable clients to expand the reach and range of their systems. Our specialty is the development of technical solutions designed to interconnect internal and external systems enabling secure, seamless integration with customers, partners and suppliers. The following are our principal capabilities and product/service offerings:
									</p>
								</div>
							</div>
							<div className="col-md-5  col-xs-12 ctnListWrap" style={{padding: "30px 0px 0px 0px"}}>
								<ul>
									<li className="" data-aos="zoom-in">
										<span className="ssibgColor" >	Build and implement business processes and supporting applications and systems</span>
									</li>
									<li className="" data-aos="zoom-in">
										
										<span  className="ssibgColor" > Migrate existing (legacy) applications to web </span>
									</li>
									<li className="" data-aos="zoom-in">
										
										<span  className="ssibgColor" > Provide web-enabling design service</span>
									</li>
									<li className="" data-aos="zoom-in">
										
										<span className="ssibgColor" > Enhance mission-critical applications</span>
									</li>
									<li className="" data-aos="zoom-in">
										
										<span className="ssibgColor" > Provide quality assurance services</span>
									</li>
									<li className="" data-aos="zoom-in">
										
										<span className="ssibgColor" > Provide technical personnel for client projects </span>
									</li>
								</ul>
							</div>
							
						</div>
					</div>
				</div>

				<div className="col-md-12 col-xs-12 drow ctnbox_wrap" style={{backgroundColor : "#fff" }}>
					<div className="container">
						<div className="col-md-10 col-md-offset-1 col-xs-12 ctnwrapper">
							<div className="col-md-12 col-xs-12 ctnbodyWrap text-center">
								<p className="ctnpara " data-aos="zoom-in">
									The key to success is the SWANTECH On-Shore/Off-Shore business model that provides the enterprise with a powerful technical delivery line through the transfer of primary development functions to its facility in Hyderabad, India. In its role as a force multiplier to amplify capabilities and relationships managed by on-shore resources, the India facilities’ infrastructure, including secure remote development systems, is being progressively upgraded to support this initiative. 
									In order to ensure the success of every project, SWANTECH has developed and implemented a Project Management Methodology which uses a rapid-prototyping, rapid-development methodology based on the industry standard spiral model. This methodology, together with the SWANTECH Reliability Framework and Project Server Portal, focuses on and enhances the delivery of the software systems based of key requirements of a successful outsourcing involvement. 
								</p>
							</div>
						</div>
					</div>
				</div>

				<div className="col-md-12 col-xs-12 drow ctnbox_wrap" style={{padding: "50px 0px"}}>
					<div className="container">
						<div className="col-md-12 col-xs-12 ctnwrapper">

							<div className="col-md-12 col-xs-12 ctnstru dcol" style={{padding: "20px 0px 0px 0px"}}>
								
								<div className="col-md-10 col-md-offset-1 col-xs-12 ctnbodyWrap text-center" style={{padding: "10px"}}>
									<p className="ctnpara " data-aos="zoom-in">
										At SWANTECH, we ascribe significance to the methodology or process of providing cost-effective and high quality IT solutions. We believe that the 'how' is as important as the 'what' and 'why'. Towards this objective, we have evolved a process that consists of the following:
									</p>
								</div>
							</div>
							<div className="col-md-12  col-xs-12 ctnListWrap ctnListHalf" style={{padding: "30px 0px 0px 0px"}}>
								<ul>
									<li  data-aos="zoom-in">
										<span className="ssibgColor" > Adopting a proven Systems Approach to Problem Solving</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="ssibgColor" > Encouraging innovation and creativity in building cost-effective solutions </span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="ssibgColor" > Incorporating the latest technology and techniques in solution designs </span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="ssibgColor" > Complementing IT solutions with improvements to the processes and methodologies used in developing and maintaining these solutions </span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="ssibgColor" > Integrating business skills and multi-platform technology expertise into the solution </span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="ssibgColor" > Developing training programs, teaching training classNamees, and providing implementation assistance (including HELP Desk Support) to ensure high customer satisfaction with the technology and the processes underlying the delivered services and solutions </span>
									</li>
									
								</ul>
							</div>
							
						</div>
					</div>
				</div>

			</div>
			<FooterNav />
		</div>

	)

}
export default SolutionsSystemIntegration;