import React,{useEffect} from "react";

import Navbar 		from './global/navbar';
import FooterNav 	from './global/FooterNav';


import './landingPage.css';

import slide01 from '../assets/img/slide01.jpg';
import slide02 from '../assets/img/slide02.jpg';
import slide03 from '../assets/img/slide03.jpg';
import equaImg from '../assets/img/Equal-Employment-Opportunity.png';
import csdImg  from "../assets/img/custome-software-development.png"
import csiImg  from "../assets/img/system-integration-icon.png"
import qaImg   from "../assets/img/quality-assurance-icon.png"




const LandingHome = () => {

	
	let svgTag = '<defs><path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" /></defs><g class="col-md-12 col-xs-12 parallax"><use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" /><use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" /><use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" /><use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" /></g>';

	useEffect(() => {
	    document.title = 'Swantech Solution';
	  }, []);

	return(
		<div className="col-md-12 col-xs-12 homeWrap dcol" style={{padding : '0px'}} >
			<Navbar />
			<div className = "col-md-12 col-xs-12 dcol homePageWrap">
				<div className="col-md-12 col-xs-12 mbanner dcol" style= {{marginTop : '60px'}}>
					<div id="twhbanner" className="col-md-5 col-md-offset-1  col-xs-12 dcol carousel slide twhbanner" data-ride="carousel">
						
					    <ol className="carousel-indicators">
				            <li className="active" data-target="#twhbanner" data-slide-to="0" ></li>
				            <li data-target="#twhbanner" data-slide-to="1" ></li>
				            <li data-target="#twhbanner" data-slide-to="2" ></li>
				         </ol>
					    <div className="carousel-inner">
				            
				    	    <div className="item active">
					            <div className="mbanner_imgwrap">
					       		    <div className="mbanner_img" style={{ backgroundImage: `url(${slide01})` }} >
					       		    </div>
					            </div>
					        </div>
					        <div className="item ">
					            <div className="mbanner_imgwrap">
					       		    <div className="mbanner_img" style={{ backgroundImage: `url(${slide02})` }} >
					       		    </div>
					            </div>
					        </div>
					        <div className="item ">
					            <div className="mbanner_imgwrap">
					       		    <div className="mbanner_img" style={{ backgroundImage: `url(${slide03})` }} >
					       		    </div>
					            </div>
					        </div>
					        
						</div>
						<a className="left carousel-control" href="#twhbanner" data-slide="prev">
					      	<span className="fa fa-angle-left"></span>
					    </a>
					    <a className="right carousel-control" href="#twhbanner" data-slide="next">
					      	<span className="fa fa-angle-right"></span>
					    </a>
						
					</div>
					<div className="col-md-6 col-xs-12 mhbanner_ctn">
						<h3 style={{color: '#fff'}} >Welcome <br/> to <br/> SwanTech Solution</h3>
						
					</div>
					<div className="wavesWrap">
					    <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto"   dangerouslySetInnerHTML={{__html: svgTag }} >
					    </svg>
					</div>
				</div>
				<div className="col-md-12 col-xs-12 drow ctnbox_wrap" style={{ backgroundColor: '#fff' }} >
					<div className="container">
						<div className="col-md-10 col-md-offset-1 col-xs-12 ctnwrapper " data-aos="flip-up">
							<div className="col-md-12 col-xs-12 ctnbodyWrap text-center ">
								<p className="ctnpara">
									<b className="txtHighlight">SWANTECH Solutions</b> is an Information Technology Products & Services Company that provides Software Products, Contingent IT Staffing, Custom Software Development and IT Solutions.
								</p>
								<p className="ctnpara">Through offices in the United States and Offshore, we provide IT products & services to Federal and State Governments, to Fortune 500 Companies, and to emerging growth businesses. We support such business sectors as Financial Services, Banking, Insurance, Retail, Healthcare, Manufacturing, Telecom, Pharmaceuticals, Oil & Gas, Transportation & Other Industries.</p>
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-12 col-xs-12 drow ctnbox_wrap">
					<div className="container">
						<div className="col-md-12 col-xs-12 ctnwrapper">
							<div className="col-md-6 col-xs-12 ctnstru dcol " data-aos="fade-right" style={{padding: "55px 0px 0px 0px" }}>
								<div className="col-md-12 col-xs-12 ctnheaderWrap">
									<h3 className="ctnheader text-center">
										Equal Employment Opportunity & Diversity
									</h3>
								</div>
								<div className="col-md-10 col-md-offset-1 col-xs-12 ctnbodyWrap text-center" style={{padding: "10px" }}>
									<p className="ctnpara">
										SWANTECH’s diversity is our strength. Our policy of Equity, Diversity and Inclusion (EDI) can assist with strategies to develop diverse talent Pools.
									</p>
								</div>
							</div>
							<div className="col-md-6 col-xs-12 ctnimgWrap  " data-aos="fade-left" style={{backgroundColor: '#fff',borderRadius: '18px' }}>
								<img src={equaImg} alt="Equal Employment Opportunity & Diversity" className="ctnimg" />
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-12 col-xs-12 drow ferwrap">

					<div className="container">

						<div className="col-md-4 col-xs-12 ferwrap_stru " data-aos="fade-right" >
							<div className="col-md-12 col-xs-12 femedia text-center"> 
					            <div className="col-md-12 col-xs-12 femedia_body">
					            	
				                    <div className="col-md-12 col-xs-12 feinfo_ctn">
				                    	<div className="col-md-12 col-xs-12 dcol">
				                    		<div className="feimgWrap">
				                    			<img src={csdImg} className = "feimg" alt="Custom Software Development" />
				                    		</div>
				                    	</div>
				                    	<div className="col-md-12 col-xs-12 dcol">
				    	                	<h3 className="fetitle">Custom Software Development</h3>
				    	                	<p className="fepara text-center">Through Our Offshore Locations, SWANTECH Provides A Service That Complements A Customer’s On-Site Information Technology Strategy With A Cost-Effective Use Of Our Offshore Software Development Facilities And Personnel</p>
				    	                </div>
				                    </div>
					            </div>
					        </div>
						</div>
						<div className="col-md-4 col-xs-12 ferwrap_stru " data-aos="fade-up" >
							<div className="col-md-12 col-xs-12 femedia text-center"> 
					            <div className="col-md-12 col-xs-12 femedia_body">
					            	
				                    <div className="col-md-12 col-xs-12 feinfo_ctn">
				                    	<div className="col-md-12 col-xs-12 dcol">
				                    		<div className="feimgWrap">
				                    			<img src={csiImg} className = "feimg" alt="Solutions and System Integration" />
				                    		</div>
				                    	</div>
				                    	<div className="col-md-12 col-xs-12 dcol">
				    	                	<h3 className="fetitle">Solutions & System Integration</h3>
				    	                	<p className="fepara text-center">Through Our Offshore Locations, SWANTECH Provides A Service That Complements A Customer’s On-Site Information Technology Strategy With A Cost-Effective Use Of Our Offshore Software Development Facilities And Personnel</p>
				    	                </div>
				                    </div>
					            </div>
					        </div>
						</div>
						<div className="col-md-4 col-xs-12 ferwrap_stru " data-aos="fade-left" >
							<div className="col-md-12 col-xs-12 femedia text-center"> 
					            <div className="col-md-12 col-xs-12 femedia_body">
					            	
				                    <div className="col-md-12 col-xs-12 feinfo_ctn">
				                    	<div className="col-md-12 col-xs-12 dcol">
				                    		<div className="feimgWrap">
				                    			<img src={qaImg} className = "feimg" alt="Quality Assurance" />
				                    		</div>
				                    	</div>
				                    	<div className="col-md-12 col-xs-12 dcol">
				    	                	<h3 className="fetitle">Quality Assurance</h3>
				    	                	<p className="fepara text-center">Our Testing Service Offerings Include Functional Testing, Performance Testing, Test Automation, Test Environment Management And Other Parts Of Application Testing. SWANTECH Can Help You Test Software Faster...</p>
				    	                </div>
				                    </div>
					            </div>
					        </div>
						</div>
				       
					</div>
				</div>	
			</div>

			<FooterNav />

		</div>
	)
}

export default LandingHome;