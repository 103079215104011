import React,{useEffect} from "react";
import Navbar 		from './global/navbar';
import FooterNav 	from './global/FooterNav';

import datawareBann 		from "../assets/img/bann14.JPG";
import GapAnalysisImg 		from "../assets/img/feban02.png";
import ETLImg				from "../assets/img/feban03.png";
import OLAPImg				from "../assets/img/feban04.png";

const Datawarehousing = () => {

	let svgTag = '<defs><path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" /></defs><g class="col-md-12 col-xs-12 parallax"><use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" /><use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" /><use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" /><use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" /></g>';

	useEffect(() => {
	    document.title = 'Datawarehousing';
	  }, []);

	return(


		<div className = "col-md-12 col-xs-12 dcol">
			<Navbar />
			<div className = "col-md-12 col-xs-12 dcol" style = {{marginTop : '60px' }} >
				<div className="col-md-12 col-xs-12 drow mhbanner_wrap datawarehouseBann" style={{ backgroundImage: `url(${datawareBann})`, minHeight: '230px', position : 'relative' }} >
					<div className="container">
						<div className="col-md-6 col-xs-12 mhbanner_ctn mxsbanner_ctn">
							<h3 style={{fontSize: "30px"}}><b style={{color: "#fff"}}>Datawarehousing </b></h3>
							
						</div>
					</div>
					<div className="wavesWrap wavesMini">
					    <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto"   dangerouslySetInnerHTML={{__html: svgTag }} >
						</svg>
					</div>
				</div>
				<div className="col-md-12 col-xs-12 pagexsHeader">
					<h3>Datawarehousing</h3>
				</div>

				<div className="col-md-12 col-xs-12 drow ctnbox_wrap" style={{backgroundColor: "#fff" }}>
					<div className="container">
						<div className="col-md-10 col-md-offset-1 col-xs-12 ctnwrapper">
							<div className="col-md-12 col-xs-12 ctnbodyWrap text-center">
								<p className="ctnpara " data-aos="zoom-in">
									While starting with a data warehouse initiative, it is important to choose a small but effective business area. We will help in identifying the right business area to focus on and help prioritize the business problems for the different phases of the data warehouse project.
								</p>
								<p className="ctnpara " data-aos="zoom-in">Data Warehousing/Business Intelligence</p>
							</div>
							<div className="col-md-12 col-xs-12 ctnheaderWrap" style = {{ padding : "20px 0px" }}>
								<h3 className="ctnheader text-center">
									Our areas of expertise in Data Warehousing/BI  include :
								</h3>
							</div>
							<div className="col-md-12 col-xs-12 ctnListWrap ctnListThree" style={{padding: "0px 0px 0px 0px" }}>
								<ul>
									<li  data-aos="zoom-in">
										
										<span className="databgColor" > Cognos</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="databgColor" > Informatica</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="databgColor" > Business Objects</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="databgColor" > IBM Datastage</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="databgColor" > Microsoft Reporting Services</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="databgColor" > Hyperion</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>


				<div className="col-md-12 col-xs-12 drow ctnbox_wrap" >
					<div className="container">
						<div className="col-md-12 col-xs-12 ctnwrapper">
							<div className="col-md-7 col-xs-12 dcol " data-aos="fade-right" >
								<div className="col-md-12 col-xs-12 ctnheaderWrap">
									<h3 className="ctnheader text-center">
										Gap Analysis
									</h3>
								</div>
								<div className="col-md-12 col-xs-12 ctnbodyWrap text-center">
									<p className="ctnpara " >We identify the missing components in the existing systems and how to build them in the data warehouse so that we can meet the requirements. In case of pre-built data warehouse implementations supplied by a vendor this phase will analyze the gap between the existing functionality in the pre-built data warehouse and the required functionality. Functional Design:-We categorize the requirements by different functional areas and arrive at the reports, dashboards, security and other components to be delivered from the data warehouse</p>
								</div>
							</div>

							<div className="col-md-5 col-xs-12 ctnimgWrap " style={{paddingTop: "40px" }} data-aos="fade-left" >
								<img src={GapAnalysisImg} alt="Gap Analysis" className="ctnimg" style = {{width: "80%" }} />
							</div>
						</div>
					</div>
				</div>

				<div className="col-md-12 col-xs-12 drow ctnbox_wrap" style={{backgroundColor: "#fff" }} >
					<div className="container">
						<div className="col-md-12 col-xs-12 ctnwrapper">
							<div className="col-md-5 col-xs-12 ctnimgWrap " style={{paddingTop: "82px" }} data-aos="fade-right" >
								<img src={ETLImg} alt="Gap Analysis" className="ctnimg" style = {{width: "80%" }} />
							</div>
							<div className="col-md-7 col-xs-12 dcol " data-aos="fade-left">
								<div className="col-md-12 col-xs-12 ctnheaderWrap">
									<h3 className="ctnheader text-center">
										ETL Tool Evaluation and Selection
									</h3>
								</div>
								<div className="col-md-12 col-xs-12 ctnbodyWrap text-center">
									<p className="ctnpara " >Many data warehouse projects were hand coded with SQL scripts and batch jobs till recently. Now, there are many ETL tools in the market. There are advantages and disadvantages with each of these tools. The decision to buy an ETL tool depends on the functionality offered vs functionality required and the budget. We will help you evaluate and select a suitable ETL tool that meets your requirements. Our consultants have excellent experience in various ETL tools, which will help in making the right decision.ssss</p>
								</div>
							</div>

							
						</div>
					</div>
				</div>

				<div className="col-md-12 col-xs-12 drow ctnbox_wrap" >
					<div className="container">
						<div className="col-md-12 col-xs-12 ctnwrapper">
							<div className="col-md-7 col-xs-12 dcol " data-aos="fade-right" >
								<div className="col-md-12 col-xs-12 ctnheaderWrap">
									<h3 className="ctnheader text-center">
										Reporting/OLAP/Ad-hoc Query Tool Evaluation and Selection
									</h3>
								</div>
								<div className="col-md-12 col-xs-12 ctnbodyWrap text-center">
									<p className="ctnpara " >Analytical tools are the front-end tools used on top of a data warehouse to slice and dice the data. These tools are generally called as On Line Analytical Processing (OLAP) tools. Depending on how the OLAP tools are built, they are categorized into four types namely ROLAP (Relational OLAP), MOLAP (Multi-Dimensional OLAP), DOLAP (Desktop OLAP) and HOLAP (Hybrid OLAP). We will help you understand the difference and pick the right tool for your analytical and reporting environment.</p>
								</div>
							</div>

							<div className="col-md-5 col-xs-12 ctnimgWrap " style={{paddingTop: "50px" }} data-aos="fade-left" >
								<img src={OLAPImg} alt="Gap Analysis" className="ctnimg" style = {{width: "90%" }} />

							</div>
						</div>
					</div>
				</div>



			</div>
			<FooterNav />

		</div>

	)

}
export default Datawarehousing;