import React,{useEffect} from "react";
import Navbar 		from './global/navbar';
import FooterNav 	from './global/FooterNav';

import pageBan7 from "../assets/img/bann07.JPG";

const QualityAssurance = () => {

	let svgTag = '<defs><path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" /></defs><g class="col-md-12 col-xs-12 parallax"><use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" /><use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" /><use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" /><use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" /></g>';

	useEffect(() => {
	    document.title = 'Quality Assurance';
	  }, []);
	

	return(
		<div className = "col-md-12 col-xs-12 dcol">
			<Navbar />
			<div className = "col-md-12 col-xs-12 dcol" style = {{marginTop : '60px' }} >
				<div className="col-md-12 col-xs-12 drow mhbanner_wrap qualityAssureBann" style={{ backgroundImage: `url(${pageBan7})`, minHeight: '230px', position : 'relative' }} >
					<div className="container">
						
					</div>
					<div className="wavesWrap wavesMini">
					    <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto"   dangerouslySetInnerHTML={{__html: svgTag }} >
						</svg>
					</div>
				</div>
				<div className="col-md-12 col-xs-12 pagexsHeader">
					<h3>Quality Assurance</h3>
				</div>

				<div className="col-md-12 col-xs-12 drow ctnbox_wrap" style={{padding: "50px 15px", backgroundColor: "#fff" }}>
					<div className="container">
						<div className="col-md-8 col-md-offset-2 col-xs-12 ctnwrapper">
							<div className="col-md-12 col-xs-12 ctnbodyWrap text-center">
								<p className="ctnpara">
									Our testing service offerings include Functional Testing, Performance Testing, Test Automation, Test Environment Management and other parts of application testing. 
								</p>
								
							</div>
						</div>
					</div>
				</div>

				<div className="col-md-12 col-xs-12 drow ctnbox_wrap" style={{padding: "50px 0px"}}>
					<div className="container">
						<div className="col-md-12 col-xs-12 ctnwrapper">

							<div className="col-md-12 col-xs-12 ctnstru dcol" style={{padding: "20px 0px 0px 0px" }}>
								
								<div className="col-md-10 col-md-offset-1 col-xs-12 ctnbodyWrap text-center" style={{padding: "10px" }}>
									<p className="ctnpara " data-aos="zoom-in" >
										Areas that we address in Quality Assurance & Application Testing ?
									</p>
								</div>
							</div>
							<div className="col-md-12  col-xs-12 ctnListWrap ctnListHalf" style={{padding: "30px 0px 0px 0px" }}>
								<ul>
									<li  data-aos="zoom-in">
										
										<span className="qabgColor" > Quality Assurance Best Practices and Strategies (for application(s) releases)</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="qabgColor" > Functional & Regression Testing (Building and executing manual test cases)</span>
									</li>
									
									<li  data-aos="zoom-in">
										
										<span className="qabgColor" > Quality Assurance Reporting and Communication (for all stakeholders)</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="qabgColor" > Performance Testing using our state of the art Performance Lab</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="qabgColor" > Staff augmentation of QA Team Leads, Project Managers and Engineers</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="qabgColor" > Automation Testing (Automation using all open source and major tools: Selenium Grid, HP, Borland, IBM, etc.)</span>
									</li>
									
								</ul>
							</div>
							<div className="col-md-12 col-xs-12 ctnstru dcol" style={{padding: "20px 0px 0px 0px" }}>
								<div className="col-md-10 col-md-offset-1 col-xs-12 ctnbodyWrap text-center" style={{padding: "10px" }}>
									<p className="ctnpara " data-aos="zoom-in" >
										SWANTECH can help you test software faster - using tools, techniques and an extended workday and help you reduce the time, effort and cost of software testing in your organization.
									</p>
								</div>
							</div>
							
						</div>
					</div>
				</div>
			</div>

			<FooterNav />
		</div>

	)

}

export default QualityAssurance;
