import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';


import './index.css';
//import App from './App';
// import reportWebVitals from './reportWebVitals';

import LandingHome                 from './components/landingPage';
import AboutUs                     from './components/aboutUsPage';
import DeliveryInnovation          from './components/DeliveryInnovation';
import GlobalDelivery              from './components/GlobalDelivery';
import ServicesPage                from './components/ServicesPage';
import CustomSoftwareDevelopment   from './components/CustomSoftwareDevelopment';
import SolutionsSystemIntegration  from './components/SolutionsSystemIntegration';
import QualityAssurance            from './components/QualityAssurance';
import StaffAugmentation           from './components/StaffAugmentation';
import RpoServices                 from './components/RpoServices';
import HrOutsourcing               from './components/HrOutsourcing';
import DevOps                      from './components/DevOps';
import WebTechnologies             from './components/WebTechnologies';
import Datawarehousing             from './components/Datawarehousing';
import CloudComputing              from './components/CloudComputing';
import Analytics                   from './components/Analytics';
import DatabasesServices           from './components/DatabasesServices';
import EnterpriseMobility          from './components/EnterpriseMobility';
import ContactUs                   from './components/ContactUs';
import CareerPage                  from './components/CareerPage';
import ProductsPage                from './components/ProductsPage';





export default function App() {

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path = "/"                                      element = { <LandingHome /> } />
        <Route path = '/about-us'                              element = { <AboutUs /> } />
        <Route path = "/delivery-innovation"                   element = { <DeliveryInnovation />} />
        <Route path = "/global-delivery"                       element = { <GlobalDelivery />} />
        <Route path = "/services"                              element = { <ServicesPage />} />
        <Route path = "/custom-software-development"           element = { <CustomSoftwareDevelopment />} />
        <Route path = "/solutions-system-integration"          element = { <SolutionsSystemIntegration />} />
        <Route path = "/quality-assurance"                     element = { <QualityAssurance />} />
        <Route path = "/staff-augmentation"                    element = { <StaffAugmentation />} />
        <Route path = "/rpo"                                   element = { <RpoServices />} />
        <Route path = "/hr-outsourcing"                        element = { <HrOutsourcing />} />
        <Route path = "/devOps"                                element = { <DevOps />} />
        <Route path = "/web-technologies"                      element = { <WebTechnologies />} />
        <Route path = "/datawarehousing"                       element = { <Datawarehousing />} />
        <Route path = "/cloud-computing"                       element = { <CloudComputing />} />
        <Route path = "/analytics"                             element = { <Analytics />} />
        <Route path = "/databases-services"                    element = { <DatabasesServices />} />
        <Route path = "/enterprise-mobility"                   element = { <EnterpriseMobility />} />
        <Route path = "/contact-us"                            element = { <ContactUs />} />
        <Route path = "/career"                                element = { <CareerPage />} />
        <Route path = "/products"                              element = { <ProductsPage />} />


      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);




/*const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();*/