import React,{useEffect} from "react";
import Navbar 		from './global/navbar';
import FooterNav 	from './global/FooterNav';

import pageBannhro from "../assets/img/bann10.JPG";

const HrOutsourcing = () => {

	let svgTag = '<defs><path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" /></defs><g class="col-md-12 col-xs-12 parallax"><use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" /><use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" /><use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" /><use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" /></g>';

	useEffect(() => {
	    document.title = 'HR Outsourcing';
	  }, []);

	return(

		<div className = "col-md-12 col-xs-12 dcol">
			<Navbar />
			<div className = "col-md-12 col-xs-12 dcol" style = {{marginTop : '60px' }} >
				<div className="col-md-12 col-xs-12 drow mhbanner_wrap deliveryInvoBann" style={{ backgroundImage: `url(${pageBannhro})`, minHeight: '230px', position : 'relative', backgroundRepeat: "no-repeat" }} >
					<div className="container">
						<div className="col-md-12 col-xs-12 mhbanner_ctn hrobanner">
							<h3 style={{fontSize: "30px"}}>HR Outsourcing </h3>
							
						</div>
					</div>
					<div className="wavesWrap wavesMini">
					    <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto"   dangerouslySetInnerHTML={{__html: svgTag }} >
						</svg>
					</div>
				</div>
				<div className="col-md-12 col-xs-12 pagexsHeader">
					<h3>HR Outsourcing</h3>
				</div>

				<div className="col-md-12 col-xs-12 drow ctnbox_wrap" style={{backgroundColor : "#fff"}}>
					<div className="container">
						<div className="col-md-10 col-md-offset-1 col-xs-12 ctnwrapper">
							<div className="col-md-12 col-xs-12 ctnbodyWrap text-center">
								<p className="ctnpara" data-aos="zoom-in">
									Any top-performing organization out there realizes the worth of having a skilled workforce. The focus in such companies is always on hiring the best and nurturing their skills further. Apparently, the competition and diverse nature of economy has also necessitated reducing the total employee cost.
								</p>
								
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-12 col-xs-12 drow ctnbox_wrap">
					<div className="container">
						<div className="col-md-10 col-md-offset-1 col-xs-12 ctnwrapper">
							<div className="col-md-12 col-xs-12 ctnbodyWrap text-center">
								<p className="ctnpara " data-aos="zoom-in">
									At SWANTECH, we offer highly competent HR outsourcing solutions to our clients, helping them to employ and retain the top-performers and at the same time reduce time and total costs. We partner companies globally, leveraging the streamlined processes and enabling them to take maximum advantage of the outsourced hr capabilities. 
								</p>
								
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-12 col-xs-12 drow ctnbox_wrap" style={{backgroundColor: "#fff" }} >
					<div className="container">
						<div className="col-md-10 col-md-offset-1 col-xs-12 ctnwrapper">
							<div className="col-md-12 col-xs-12 ctnbodyWrap text-center">
								<p className="ctnpara " data-aos="zoom-in">
									Outsourcing HR helps companies to trim down the overall costs and free resources so that they can be devoted to the core functions and revenue-generating operations of the enterprise.
								</p>
								
							</div>
						</div>
					</div>
				</div>

				<div className="col-md-12 col-xs-12 drow ctnbox_wrap" >
					<div className="container">
						<div className="col-md-10 col-md-offset-1 col-xs-12 ctnwrapper">
							<div className="col-md-12 col-xs-12 ctnheaderWrap">
								<h3 className="ctnheader text-center " data-aos="zoom-in" >
									Our Services include
								</h3>
							</div>
							<div className="col-md-12 col-xs-12 ctnListWrap ctnListHalf" style={{ padding: "30px 0px 0px 0px"}}>
								<ul>
									
									<li  data-aos="zoom-in">
										
										<span className="hrobgColor" > Handling termination of employees.</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="hrobgColor" > Handling operations like payroll processing and taxes</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="hrobgColor" > Creating job descriptions and staff handbooks and accelerating the procedures of new hiring</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="hrobgColor" > Managing operations like Employee Benefits administration and invoice settlement.</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="hrobgColor" > Handle the safety programs for employees, and OSHA compliance duties.</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="hrobgColor" > Setting up employee guidance and official procedures for hiring new people</span>
									</li>
									
								</ul>
							</div>
						</div>
					</div>
				</div>

				

			</div>
			<FooterNav />

		</div>

	)

}
export default HrOutsourcing;