import React,{useEffect} from "react";
import Navbar 		from './global/navbar';
import FooterNav 	from './global/FooterNav';

import pageBann3 from "../assets/img/bann03.JPG";

const GlobalDelivery = () => {

	let svgTag = '<defs><path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" /></defs><g class="col-md-12 col-xs-12 parallax"><use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" /><use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" /><use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" /><use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" /></g>';

	useEffect(() => {
	    document.title = 'Delivery Innovation';
	  }, []);

	return(
		<div className = "col-md-12 col-xs-12 dcol">
			<Navbar />
			<div className = "col-md-12 col-xs-12 dcol" style = {{marginTop : '60px' }} >

				<div className="col-md-12 col-xs-12 drow mhbanner_wrap deliveryInvoBann" style={{ backgroundImage: `url(${pageBann3})`, minHeight: '230px', position : 'relative' }} >
					<div className="container">
						<div className="col-md-12 col-xs-12 mhbanner_ctn mxsbanner_ctn text-center">
							<h3 style={{fontSize: "30px" }}><b style={{color: "#fff"}}>Global Delivery</b></h3>
							
						</div>
					</div>
					<div className="wavesWrap wavesMini">
					    <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto"   dangerouslySetInnerHTML={{__html: svgTag }} >
						</svg>
					</div>
				</div>
				<div className="col-md-12 col-xs-12 pagexsHeader">
					<h3>Global Delivery</h3>
				</div>

				<div className="col-md-12 col-xs-12 drow ctnbox_wrap" style={{backgroundColor: '#fff'}}>
					<div className="container">
						<div className="col-md-10 col-md-offset-1 col-xs-12 ctnwrapper">
							<div className="col-md-12 col-xs-12 ctnbodyWrap text-center">
								<p className="ctnpara " data-aos="flip-up" >
									Knowledge transfer and open communications in global time-zones are no longer the most challenging aspects of distributed global operations. Rather, diverse cultures, multiple systems and complex interfaces must be understood, defined and managed seamlessly. The best global delivery models successfully navigate these complexities to lower the total cost of ownership, increase service levels and streamline new efficiencies.
								</p>

								<p className="ctnpara " data-aos="flip-up" >
									SWANTECH manages and maintains a 24×7 culture across critical work-streams. As an experienced services delivery partner, we work with you to establish a well-defined execution strategy that supports your business objectives and offers predictable service levels. Unlike other large, global outsourcing firms, SWANTECH prides itself with being able to quickly adapt our service delivery models to best suit your business needs.
								</p>
								
							</div>
						</div>
					</div>
				</div>

			</div>
			<FooterNav />
		</div>


	)

}
export default GlobalDelivery;