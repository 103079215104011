import React,{useEffect} from "react";
import Navbar 		from './global/navbar';
import FooterNav 	from './global/FooterNav';

import pageBann5 from "../assets/img/bann05.JPG";

const CustomSoftwareDevelopment = () => {

	let svgTag = '<defs><path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" /></defs><g class="col-md-12 col-xs-12 parallax"><use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" /><use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" /><use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" /><use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" /></g>';

	useEffect(() => {
	    document.title = 'Custom Software Development';
	  }, []);

	return(

		<div className = "col-md-12 col-xs-12 dcol">
			<Navbar />
			<div className = "col-md-12 col-xs-12 dcol" style = {{marginTop : '60px' }} >

				<div className="col-md-12 col-xs-12 drow mhbanner_wrap custdevBann" style={{ backgroundImage: `url(${pageBann5})`, minHeight: '230px', position : 'relative' }} >
					<div className="container">
						<div className="col-md-9 col-xs-12 mhbanner_ctn mxsbanner_ctn">
							<h3 style={{fontSize: "30px"}}><b style={{color: "#fff"}}>Custom Software Development</b></h3>
							
						</div>
					</div>
					<div className="wavesWrap wavesMini">
					    <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto"   dangerouslySetInnerHTML={{__html: svgTag }} >
						</svg>
					</div>
				</div>

				<div className="col-md-12 col-xs-12 pagexsHeader">
					<h3>Custom Software Development</h3>
				</div>

				<div className="col-md-12 col-xs-12 drow ctnbox_wrap" style={{backgroundColor: "#fff"}}>
					<div className="container">
						<div className="col-md-10 col-md-offset-1 col-xs-12 ctnwrapper">
							<div className="col-md-12 col-xs-12 ctnbodyWrap text-center">
								<p className="ctnpara">
									Through our offshore locations, SWANTECH provides a service that complements a customer’s on-site Information Technology Strategy with a cost-effective use of our offshore software development facilities and personnel. Through our GOS Service, our customers can save on development time and costs including labor, infrastructures, ramp-up time, learning curves, cultural barriers, and language problems. Further, SWANTECH assumes responsibility for management of the offshore activities and its product deliverables
								</p>
							</div>
						</div>
					</div>
				</div>

				<div className="col-md-12 col-xs-12 drow ctnbox_wrap" style={{padding: "50px 0px"}}>
					<div className="container">
						<div className="col-md-12 col-xs-12 ctnwrapper">

							<div className="col-md-6 col-xs-12 ctnstru dcol" style={{padding: "20px 0px 0px 0px"}}>
								
								<div className="col-md-10 col-md-offset-1 col-xs-12 ctnbodyWrap text-center " data-aos="fade-right"  style={{ padding: "10px"}}>
									<p className="ctnpara">
										SWANTECH has high expertise in all major technologies to guarantee the use of best-of-breed tools and technologies available at any given time. Our work philosophy is to keep ourselves equipped and well versed with cutting-edge solutions to offer our clients the maximum benefits technology will allow:
									</p>
								</div>
							</div>
							<div className="col-md-5 col-md-offset-1 col-xs-12 ctnListWrap" style={{ padding: "5px 0px 0px 0px"}}>
								<ul>
									<li className="" data-aos="zoom-in">
										
										<span className="csdbgColor" > Web Technologies (J2EE, Microsoft Visual Studio.NET)</span>
									</li>
									<li className="" data-aos="zoom-in">
										
										<span className="csdbgColor" > ERP/CRM (SAP, Oracle Applications) </span>
									</li>
									<li className="" data-aos="zoom-in">
										
										<span className="csdbgColor" > Database Architecture and Administration</span>
									</li>
									<li className="" data-aos="zoom-in">
										
										<span className="csdbgColor" > Networking Management and Architectures</span>
									</li>
									<li className="" data-aos="zoom-in">
										
										<span className="csdbgColor" > Mainframe and Legacy Systems</span>
									</li>
									<li className="" data-aos="zoom-in">
										
										<span className="csdbgColor" > Data warehousing & Business Intelligence </span>
									</li>
									<li className="" data-aos="zoom-in">
										
										<span className="csdbgColor" > EIA (Web Methods, JAVA CAPS (Seebeyond), Tibco)</span>
									</li>
									<li className="" data-aos="zoom-in">
										
										<span className="csdbgColor" > Quality Assurance </span>
									</li>
								</ul>
							</div>
							
						</div>
					</div>
				</div>


			</div>

			<FooterNav />

		</div>


	)

}

export default CustomSoftwareDevelopment;