import React,{useEffect} from "react";
import Navbar 		from './global/navbar';
import FooterNav 	from './global/FooterNav';

import devbann from "../assets/img/bann12.JPG";

const DevOps = () => {

	let svgTag = '<defs><path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" /></defs><g class="col-md-12 col-xs-12 parallax"><use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" /><use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" /><use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" /><use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" /></g>';

	useEffect(() => {
	    document.title = 'DevOps';
	  }, []);

	return(
		<div className = "col-md-12 col-xs-12 dcol">
			<Navbar />
			<div className = "col-md-12 col-xs-12 dcol" style = {{marginTop : '60px' }} >


				<div className="col-md-12 col-xs-12 drow mhbanner_wrap devopsBann" style={{ backgroundImage: `url(${devbann})`, minHeight: '230px', position : 'relative' }} >
					<div className="container">
						
					</div>
					<div className="wavesWrap wavesMini">
					    <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto"   dangerouslySetInnerHTML={{__html: svgTag }} >
						</svg>
					</div>
				</div>
				<div className="col-md-12 col-xs-12 pagexsHeader">
					<h3>DevOps</h3>
				</div>

				<div className="col-md-12 col-xs-12 drow ctnbox_wrap" style={{backgroundColor: "#fff"}}>
					<div className="container">
						<div className="col-md-10 col-md-offset-1 col-xs-12 ctnwrapper">
							<div className="col-md-12 col-xs-12 ctnbodyWrap text-center">
								<p className="ctnpara " data-aos="zoom-in">
									DevOps is a set of practices that automates the processes between Software Development and IT teams which will help to build, test and release reliable Software in a shorter time span. The concept of DevOps is founded on building a culture of collaboration between teams that historically functioned separately. Benefits include increased trust, faster Software releases, ability to solve critical issues quickly and better management of unplanned work, savings of 20-30% on IT Projects and faster ROI.
								</p>
							</div>
						</div>
					</div>
				</div>

				<div className="col-md-12 col-xs-12 drow ctnbox_wrap" >
					<div className="container">
						<div className="col-md-10 col-md-offset-1 col-xs-12 ctnwrapper">
							<div className="col-md-12 col-xs-12 ctnbodyWrap text-center">
								<p className="ctnpara " data-aos="zoom-in">
									DevOps as a Service: SWANTECH helps accelerate the adoption of DevOps using the organization, process and technology as pathways to success.
								</p>
							</div>
						</div>
					</div>
				</div>

				<div className="col-md-12 col-xs-12 drow ctnbox_wrap" style={{ backgroundColor: "#fff" }} >
					<div className="container">
						<div className="col-md-10 col-md-offset-1 col-xs-12 ctnwrapper">
							<div className="col-md-12 col-xs-12 ctnheaderWrap">
								<h3 className="ctnheader text-center " data-aos="zoom-in">
									DevOps Tool Chain
								</h3>
							</div>
							<div className="col-md-12 col-xs-12 ctnListWrap ctnListHalf" style={{ padding: "30px 0px 0px 0px" }}>
								<ul>
									
									
									<li  data-aos="zoom-in">
										
										<span className="devbgColor" > You can select the tools you want for SCM, Build, Test, Artifact Repository, Deployment/Configuration Management tools and infrastructure.</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="devbgColor" > No need to have staff, to manage these tools, we will manage it for you so you can focus on Developing Software Applications.</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="devbgColor" > We will deliver an integrated DevOps tool chain that meets your business needs.</span>
									</li>
									
									<li  data-aos="zoom-in">
										
										<span className="devbgColor" > Management tool gives you full visibility for the entire workflow for your application.</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="devbgColor" > Consume DevOps tool chain as a service.</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>

				<div className="col-md-12 col-xs-12 drow ctnbox_wrap">
					<div className="container">
						<div className="col-md-10 col-md-offset-1 col-xs-12 ctnwrapper">
							<div className="col-md-12 col-xs-12 ctnheaderWrap">
								<h3 className="ctnheader text-center " data-aos="zoom-in">
									DevOps Maturity Assessment
								</h3>
							</div>
							<div className="col-md-12 col-xs-12 ctnbodyWrap text-center">
								<p className="ctnpara " data-aos="zoom-in">
									SWANTECH can help you with assessing your current DevOps process maturity and create a Roadmap. We can prepare and manage the entire process beginning with Organization, Process and tools to support your DevOps journey which will help you to focus on your core Business Applications.
								</p>
							</div>
						</div>
					</div>
				</div>

				<div className="col-md-12 col-xs-12 drow ctnbox_wrap" style={{ backgroundColor: "#fff" }}>
					<div className="container">
						<div className="col-md-10 col-md-offset-1 col-xs-12 ctnwrapper">
							<div className="col-md-12 col-xs-12 ctnheaderWrap">
								<h3 className="ctnheader text-center " data-aos="zoom-in">
									DevOps Implementation by SWANTECH Systems Includes, but not limited to
								</h3>
							</div>
							<div className="col-md-12 col-xs-12 ctnListWrap ctnListHalf" style={{ padding: "30px 0px 0px 0px" }}>
								<ul>
									<li  data-aos="zoom-in">
										
										<span className="devbgColor" > Maturity Assessment.</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="devbgColor" > DevOps roadmap creation.</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="devbgColor" > DevOps process creation.</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="devbgColor" > Manage the necessary changes in the organization.</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="devbgColor" > Selection of DevOps tool and creation of CI/CD workflows.</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="devbgColor" > Infrastructure and Application deployment automation.</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="devbgColor" > Support and Maintenance.</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>


				<div className="col-md-12 col-xs-12 drow ctnbox_wrap" >
					<div className="container">
						<div className="col-md-10 col-md-offset-1 col-xs-12 ctnwrapper">
							<div className="col-md-12 col-xs-12 ctnheaderWrap">
								<h3 className="ctnheader text-center " data-aos="zoom-in">
									Benefits
								</h3>
							</div>
							<div className="col-md-12 col-xs-12 ctnListWrap ctnListHalf" style={{ padding: "30px 0px 0px 0px" }}>
								<ul>
									<li  data-aos="zoom-in">
										
										<span className="devbgColor" > Innovation</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="devbgColor" > Strong team collaboration and tighter integration</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="devbgColor" > Agility, Shorter Development Cycle, Service Quality and Reliable Service Delivery.</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="devbgColor" > Increases the efficiency of development and operations teams</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="devbgColor" > Deliver higher value to businesses and clients. Faster ROI</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="devbgColor" > Helps you to better manage the unplanned work.</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="devbgColor" > Costs reduction through automation.</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>


			</div>
			<FooterNav />

		</div>


	)

}

export default DevOps;