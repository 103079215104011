import React from 'react';
import { Outlet, Link } from "react-router-dom";
import footerLogo from '../../assets/img/logo2.png';

const FooterNav = () => {

	return(

		<div className="col-md-12 col-xs-12 footer_wraper">
			<div className="col-md-10 col-md-offset-1 col-xs-12 data_col">
				<div className="col-md-12 col-xs-12 footer_stru">
					<div className="col-md-4 col-xs-12 col-sm-6 footer_ctn">
						<div className="col-md-12 col-xs-12 fobody">
							<ul>
								<li>
									<Link to="/" >
										<label > Home </label>
									</Link>
								</li>
								<li>
									<Link to="/about-us" >
										<label > About Us </label>
									</Link>
								</li>
								<li>
									<Link to="/services" >
										<label > Services </label>
									</Link>
								</li>
								<li>
									<Link to="/contact-us" >
										<label > Contact Us </label>
									</Link>
								</li>
								<li>
									<Link to="/career" >
										<label > Career </label>
									</Link>
								</li>
								<li>
									<Link to="/products" >
										<label > Products </label>
									</Link>
								</li>
								
							</ul>
						</div>
					</div>
					<div className="col-md-4 col-xs-12 col-sm-6 footer_ctn">
						<div className="col-md-12 col-xs-12 fobody text-center" style={{padding: "0px 0px 0px 0px"}}>
							<div className="col-md-12 col-xs-12 drow" style={{padding: '0px' }}> 
								<div className="twlogo" style={{ backgroundImage: `url(${footerLogo})`, margin: '0px auto', backgroundPosition: "top center" }}></div>
		        		    </div>
						</div>
					</div>
					<div className="col-md-4 col-xs-12 col-sm-6 footer_ctn">
						<div className="col-md-12 col-xs-11  fobody">  
							<div className="col-md-12 col-xs-12 data_row">
								<div className="col-md-1 col-xs-1 data_col">
									<span className="fa fa-globe data_icon"></span>
								</div>
								<div className="col-md-10 col-xs-9 data_col">
									<label className=" data_label">216 S Pine Ave, <br/> Inverness, FLORIDA 34452</label>
								</div>
							</div>
							<div className="col-md-12 col-xs-12 data_row">
								<div className="col-md-1 col-xs-1 data_col">
									<span className="fa fa-envelope data_icon"></span>
								</div>
								<div className="col-md-10 col-xs-9 data_col">
								    <a href="mailto:info@swantechsolutions.co" target="_blank">
									<label className=" data_label">info@swantechsolution.com </label>
									</a>
								</div>
							</div>
							<div className="col-md-12 col-xs-12 data_row">
								<div className="col-md-1 col-xs-1 data_col">
									<span className="fa fa-phone data_icon"></span>
								</div>
								<div className="col-md-10 col-xs-9 data_col">
								    <a href="tel:+1 (334) 237-4216">
									    <label className=" data_label">+1 (334) 237-4216</label>
									</a>
								</div>
							</div>
							

						</div>
					</div>
					
					
				</div>
				
				

			</div>
			<div className="col-md-12 col-xs-12 footer_btn text-center">
				<label className="data_label mont_font" style={{textTransform: 'capitalize !important'}}> Copyright 2023 SWANTECH SOLUTION © all rights reserved.</label>
			</div>
		</div>
	)

}
export default FooterNav;