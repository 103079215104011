import React,{useEffect} from "react";
import Navbar 		from './global/navbar';
import FooterNav 	from './global/FooterNav';

import serpageBann from "../assets/img/bann04.JPG";

const ServicesPage = () => {

	let svgTag = '<defs><path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" /></defs><g class="col-md-12 col-xs-12 parallax"><use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" /><use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" /><use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" /><use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" /></g>';

	useEffect(() => {
	    document.title = 'Services';
	  }, []);

	return(


		<div className = "col-md-12 col-xs-12 dcol">
			<Navbar />
			<div className = "col-md-12 col-xs-12 dcol" style = {{marginTop : '60px' }} >
				<div className="col-md-12 col-xs-12 drow mhbanner_wrap serpageBann" style={{ backgroundImage: `url(${serpageBann})`, minHeight: '230px', position : 'relative' }} >
					<div className="container">
						<div className="col-md-12 col-xs-12 mhbanner_ctn mxsbanner_ctn">
							<h3 style={{fontSize: "30px"}}>Services</h3>
							
						</div>
					</div>
					<div className="wavesWrap wavesMini">
					    <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto"   dangerouslySetInnerHTML={{__html: svgTag }} >
						</svg>
					</div>
				</div>
				<div className="col-md-12 col-xs-12 pagexsHeader">
					<h3>Services</h3>
				</div>

				<div className="col-md-12 col-xs-12 drow ctnbox_wrap" style={{backgroundColor: "#fff"}}>
					<div className="container">
						<div className="col-md-10 col-md-offset-1 col-xs-12 ctnwrapper">
							<div className="col-md-12 col-xs-12 ctnbodyWrap text-center">
								<p className="ctnpara " data-aos="zoom-in" >
									Optimizing IT investments while constantly evolving more efficient business models is a major challenge that most organizations face today. SWANTECH’s innovative solutions across several technology platforms, help customers transform themselves to stay ahead of competition and optimize their business processes
								</p>
								<p className="ctnpara " data-aos="zoom-in">
									Our unmatched customer-focus and value propositions backed by mature processes and industry experience distinguish our service offerings. Complemented by our comprehensive skills and process capabilities, SWANTECH’s flexible solutions ensure maximum performance, greater ROI, and faster business results for our customers.
								</p>
							</div>
						</div>
					</div>
				</div>

				<div className="col-md-12 col-xs-12 drow ctnbox_wrap" style={{padding: "50px 0px" }}>
					<div className="container">
						<div className="col-md-12  col-xs-12 ctnwrapper dcol">
							<div className="col-md-12 col-xs-12 dcol">
								<div className="col-md-4 col-xs-12 fetchboxWrap " data-aos="zoom-in" >
									<div className="col-md-12 col-xs-12 fetchStru">
										<h3 className="fetchTitle">Custom Software Development </h3>
										<p className="fetchpara">Through our offshore locations, SWANTECH provides a service that complements a customer’s on-site Information Technology Strategy with a cost-effective use of our offshore software development. </p>
									</div>
								</div>
								<div className="col-md-4 col-xs-12 fetchboxWrap whitebg " data-aos="zoom-in">
									<div className="col-md-12 col-xs-12 fetchStru">
										<h3 className="fetchTitle">Solutions & System Integration </h3>
										<p className="fetchpara">We design, develop and implement reliable applications that run securely on internal networks and over the Internet. We have successfully completed 100% of our projects on time and on budget. </p>
									</div>
								</div>
								<div className="col-md-4 col-xs-12 fetchboxWrap " data-aos="zoom-in" style={{border: "none"}}>
									<div className="col-md-12 col-xs-12 fetchStru" >
										<h3 className="fetchTitle">Quality Assurance  </h3>
										<p className="fetchpara">Our testing service offerings include Functional Testing, Performance Testing, Test Automation, Test Environment Management and other parts of application testing.</p>
									</div>
								</div>
							</div>
							<div className="col-md-12 col-xs-12 dcol">
								<div className="col-md-4 col-xs-12 fetchboxWrap whitebg " data-aos="zoom-in">
									<div className="col-md-12 col-xs-12 fetchStru">
										<h3 className="fetchTitle">Staff Augmentation  </h3>
										<p className="fetchpara">The corporate world is sated with challenges – particularly when it comes to the skills and experience of the professionals working for an organization. To ensure that a company’s customers receive a steady. </p>
									</div>
								</div>
								<div className="col-md-4 col-xs-12 fetchboxWrap " data-aos="zoom-in" >
									<div className="col-md-12 col-xs-12 fetchStru">
										<h3 className="fetchTitle">RPO  </h3>
										<p className="fetchpara">With its RPO Services, SWANTECH makes sure that each resume is systematically screened. That includes screening resumes sourced from online portals, search engines, emails, and various job networks on the internet. </p>
									</div>
								</div>
								<div className="col-md-4 col-xs-12 fetchboxWrap whitebg " data-aos="zoom-in" style={{border: "none"}}>
									<div className="col-md-12 col-xs-12 fetchStru" >
										<h3 className="fetchTitle">HR Outsourcing   </h3>
										<p className="fetchpara">Any top-performing organization out there realizes the worth of having a skilled workforce. The focus in such companies is always on hiring the best and nurturing their skills further.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>


			</div>
			<FooterNav />
		</div>
	)
}
export default ServicesPage;