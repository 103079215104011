import React,{useEffect} from "react";
import Navbar 		from './global/navbar';
import FooterNav 	from './global/FooterNav';

import dataserBann from "../assets/img/bann17.jpg";

const DatabasesServices = () => {

	let svgTag = '<defs><path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" /></defs><g class="col-md-12 col-xs-12 parallax"><use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" /><use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" /><use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" /><use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" /></g>';

	useEffect(() => {
	    document.title = 'Databases Services';
	  }, []);

	return(


		<div className = "col-md-12 col-xs-12 dcol">
			<Navbar />
			<div className = "col-md-12 col-xs-12 dcol" style = {{marginTop : '60px' }} >
				<div className="col-md-12 col-xs-12 drow mhbanner_wrap databaseServBann" style={{ backgroundImage: `url(${dataserBann})`, minHeight: '230px', position : 'relative' }} >
					<div className="container">
						<div className="col-md-11 col-xs-12 mhbanner_ctn mxsbanner_ctn">
							<h3 style={{fontSize: "30px"}}><b style={{color: "#fff"}}>DataBase Services </b></h3>
						</div>
					</div>
					<div className="wavesWrap wavesMini">
					    <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto"   dangerouslySetInnerHTML={{__html: svgTag }} >
						</svg>
					</div>
				</div>
				<div className="col-md-12 col-xs-12 pagexsHeader">
					<h3>DataBase Services</h3>
				</div>

				<div className="col-md-12 col-xs-12 drow ctnbox_wrap" style={{ backgroundColor: "#fff" }}>
					<div className="container">
						<div className="col-md-10 col-md-offset-1 col-xs-12 ctnwrapper">
							<div className="col-md-12 col-xs-12 ctnbodyWrap text-center">
								<p className="ctnpara " data-aos="zoom-in">
									We are experienced in providing Data modeling, database design (Logical, Physical), Development & Performance Tuning services on the below listed databases.
								</p>
							</div>
							<div className="col-md-12 col-xs-12 ctnListWrap ctnListThree" style={{padding: "0px 0px 0px 0px" }}>
								<ul>
									<li  data-aos="zoom-in">
										
										<span className="databgColor" > Oracle Database.</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="databgColor" > Microsoft SQL Server.</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="databgColor" > Microsoft Access.</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="databgColor" > Sybase.</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="databgColor" > UDB/DB2.</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>

				<div className="col-md-12 col-xs-12 drow ctnbox_wrap">
					<div className="container">
						<div className="col-md-10 col-md-offset-1 col-xs-12 ctnwrapper">
							<div className="col-md-12 col-xs-12 ctnbodyWrap text-center">
								<p className="ctnpara " data-aos="zoom-in">
									Our database services group provides professional database design, development, and maintenance services and assistance with an existing databases systems in production.
								</p>
								<p className="ctnpara " data-aos="zoom-in">Our expert services include assessment, software selection, application development, data conversion & support for the chosen database. From designing and architecting new database systems to database tuning, to seamlessly maintaining large production systems, our team works at our clients to provide reliable, scalable, high-performing database systems.</p>
							</div>
						</div>
					</div>
				</div>

			</div>

			<FooterNav />

		</div>
	)
}
export default DatabasesServices;