import React,{useEffect} from "react";
import Navbar 		from './global/navbar';
import FooterNav 	from './global/FooterNav';

import abbannImg from "../assets/img/bann01.JPG";

const AboutUs = () => {

	let svgTag = '<defs><path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" /></defs><g class="col-md-12 col-xs-12 parallax"><use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" /><use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" /><use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" /><use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" /></g>';

	useEffect(() => {
	    document.title = 'About Us';
	  }, []);

	return(
		<div className = "col-md-12 col-xs-12 dcol">
			<Navbar />

			<div className = "col-md-12 col-xs-12 dcol" style = {{marginTop : '60px' }} >
				<div className="col-md-12 col-xs-12 drow mhbanner_wrap abutpageBanner" style={{ backgroundImage: `url(${abbannImg})`, minHeight: '230px' }} >
					<div className="container">
						<div className="col-md-6 col-xs-12 mhbanner_ctn mxsbanner_ctn">
							<h3><b>About Us</b></h3>
							
						</div>
					</div>
					<div className="wavesWrap wavesMini">
					    <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto"   dangerouslySetInnerHTML={{__html: svgTag }} >
						</svg>
					</div>
				</div>
				<div className="col-md-12 col-xs-12 pagexsHeader">
					<h3>About Us</h3>
				</div>

				<div className="col-md-12 col-xs-12 drow ctnbox_wrap" style={{backgroundColor: "#fff"}}>
					<div className="container">
						<div className="col-md-10 col-md-offset-1 col-xs-12 ctnwrapper">
							<div className="col-md-12 col-xs-12 ctnbodyWrap text-center">
								<p className="ctnpara " data-aos="zoom-in">
									SWANTECH is helping companies change existing business paradigms by bringing tomorrow’s AI and Automation solutions to today’s businesses.
								</p>
								<p className="ctnpara " data-aos="zoom-in">Disruptive new technologies are helping corporations become more agile, competitive and profitable. Predictive algorithms are provisioning customer orders more accurately than ever before. Cognitive technologies are enhancing the customer experience at every touch point and open-ledger validation methods are improving the accuracy of complex, global financial transactions. Automation is no longer a choice, it is the engine that drives business growth.</p>
							</div>
						</div>
					</div>
				</div>

				<div className="col-md-12 col-xs-12 drow ctnbox_wrap" style={{padding: "50px 0px"}}>
					<div className="container">
						<div className="col-md-12 col-xs-12 ctnwrapper">

							<div className="col-md-6 col-xs-12 ctnstru dcol " data-aos="zoom-in" style={{padding: "20px 0px 0px 0px"}}>
								<div className="col-md-12 col-xs-12 ctnheaderWrap">
									<h3 className="ctnheader text-center" style={{textTransform: "capitalize"}} >
										A Young  IT Consulting services company
									</h3>
								</div>
								<div className="col-md-10 col-md-offset-1 col-xs-12 ctnbodyWrap text-center" style={{padding: "10px"}}>
									<p className="ctnpara " >
										the SWANTECH Solutions is leaving impact across North America, Europe, Asia and Australia, providing a range of services such as
									</p>
								</div>
							</div>
							<div className="col-md-5 col-md-offset-1 col-xs-12 ctnListWrap" style={{padding: "55px 0px 0px 0px"}}>
								<ul>
									<li  data-aos="zoom-in">
										
										<span className="abubgColor" > Automation & Innovation</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="abubgColor" > Architecture & Consulting</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="abubgColor" > Maintenance & Support</span>
									</li>
									<li  data-aos="zoom-in">
										
										<span className="abubgColor" > Contingent Workforce</span>
									</li>
								</ul>
							</div>
							
						</div>
					</div>
				</div>


			</div>

			<FooterNav />
		</div>
	)
}

export default AboutUs;